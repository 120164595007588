@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Varela+Round&display=auto");

body,
html {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    background-color: #F0EEFF;
    font-family: "Inter", sans-serif;
    cursor: default;
}

input,
textarea,
select,
button {
    font-family: "Inter", sans-serif;
}

div,
a,
span,
input,
textarea,
select {
    -webkit-tap-highlight-color: transparent;
}

div::-moz-focus-inner,
span::-moz-focus-inner,
input::-moz-focus-inner,
textarea::-moz-focus-inner,
select::-moz-focus-inner {
    border: 0;
}

input:focus,
div:focus,
h1:focus,
h2:focus,
h3:focus,
span:focus,
textarea:focus,
select:focus {
    outline: none;
    background-image: none;
}

input::-ms-clear {
    display: none;
}

form {
    display: block;
    margin: 0;
    padding: 0;
}

.touch {
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#root {
    height: 100%;
}

/* LOADER */

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(16, 13, 38, 0.4);
    opacity: 0;
    animation-name: loader-show;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    z-index: 99;
}

@keyframes loader-show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.spinner {
    width: 54px;
    height: 54px;
    animation: spinner-rotate 1.75s linear infinite;
}

.spinner-path {
    stroke: #FFFFFF;
    stroke-linecap: round;
    animation: spinner-dash 1.3125s ease-in-out infinite;
}

@keyframes spinner-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinner-dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

/* HOME */

.home-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}

.home-bar-on {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 24px 0px rgba(72, 55, 171, 0.2);
    backdrop-filter: blur(16px);
}

.home-bar-frame {
    display: flex;
    width: 100%;
    max-width: 1920px;
    padding-left: 48px;
    padding-right: 48px;
    margin: 0 auto;
    box-sizing: border-box;
}

.home-bar-brand {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-bar-brand-link {
    display: block;
}

.home-bar-brand img {
    display: block;
    height: 42px;
}

.home-bar-brand img:nth-child(1) {
    display: block;
}

.home-bar-brand img:nth-child(2) {
    display: none;
}

.home-bar-on .home-bar-brand img:nth-child(1) {
    display: none;
}

.home-bar-on .home-bar-brand img:nth-child(2) {
    display: block;
}

.home-bar-buttons {
    flex: auto;
}

.home-bar-actions {
    flex: none;
    display: flex;
    column-gap: 14px;
    padding-top: 32px;
    padding-bottom: 32px;
    transition: 0.18s;
}

.home-bar-on .home-bar-actions {
    padding-top: 20px;
    padding-bottom: 20px;
}

.home-action {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: #D163AB;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
    transition: 0.18s;
}

.home-action:hover {
    background-color: #DB68B4;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75);
}

.touch .home-action:hover {
    background-color: #D163AB;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
}

.home-action:active,
.touch .home-action:active {
    background-color: #DB68B4;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75);
}

.home-action-alt {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    border-radius: 100px;
    border: solid 2px rgba(209, 99, 171, 0.6);
    background-color: rgba(209, 99, 171, 0);
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: 0.18s;
    backface-visibility: hidden;
}

.home-bar-on .home-action-alt {
    color: #D163AB;
}

.home-action-alt img {
    display: inline-block;
    width: auto;
    height: 12px;
    margin: 0 0 0;
    margin-top: 0px;
    margin-left: 8px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.home-action-alt-arrow-1 {
    display: inline-block !important;
}

.home-action-alt-arrow-2 {
    display: none !important;
}

.home-bar-on .home-action-alt-arrow-1 {
    display: none !important;
}

.home-bar-on .home-action-alt-arrow-2 {
    display: inline-block !important;
}

.home-action-alt:hover {
    border: solid 2px rgba(209, 99, 171, 1);
    background-color: rgba(209, 99, 171, 0.15);
}

.touch .home-action-alt:hover {
    border: solid 2px rgba(209, 99, 171, 0.6);
    background-color: rgba(209, 99, 171, 0);
}

.home-action-alt:active,
.touch .home-action-alt:active {
    border: solid 2px rgba(209, 99, 171, 1);
    background-color: rgba(209, 99, 171, 0.15);
}

.home-action-alt:hover img {
    transform: translateX(4px);
}

.touch .home-action-alt:hover img {
    transform: translateX(0px);
}

.home-action-alt:active img,
.touch .home-action-alt:active img {
    transform: translateX(4px);
}

.home-bar-menu {
    flex: none;
    display: none;
    justify-content: center;
    align-items: center;
}

.home-bar-menu-btn {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 50px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.home-bar-menu-line {
    width: 100%;
    height: 3px;
    border-radius: 100px;
    background-color: #DB68B4;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
    transition: 0.18s;
}

.home-bar-menu-btn:hover .home-bar-menu-line {
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 1);
}

.touch .home-bar-menu-btn:hover .home-bar-menu-line {
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
}

.home-bar-menu-btn:active .home-bar-menu-line,
.touch .home-bar-menu-btn:active .home-bar-menu-line {
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 1);
}

/* MOBILE MENU */

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #9748A3;
    background: -moz-linear-gradient(45deg, #9748A3 0%, #624DE3 100%);
    background: -webkit-linear-gradient(45deg, #9748A3 0%, #624DE3 100%);
    background: linear-gradient(45deg, #9748A3 0%, #624DE3 100%);
    z-index: 9;
    opacity: 0;
    transform: scale(0.95);
    animation: mobile-menu-anima 0.25s ease-in-out forwards;
}

@keyframes mobile-menu-anima {
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.mobile-menu-close {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-radius: 100px;
    background-color: #FFFFFF;
    cursor: pointer;
    z-index: 13;
    transition: 0.18s;
}

.mobile-menu-close img {
    display: block;
    width: 14px;
}

.mobile-menu-close:hover {
    box-shadow: 0px 0px 0px 5px rgba(209, 99, 171, 0.25);
}

.touch .mobile-menu-close:hover {
    box-shadow: none;
}

.mobile-menu-close:active,
.touch .mobile-menu-close:active {
    box-shadow: 0px 0px 0px 5px rgba(209, 99, 171, 0.25);
}

.mobile-menu-content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    padding: 24px;
    box-sizing: border-box;
}

.mobile-menu-actions {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    padding-bottom: 26px;
}

.mobile-menu-link,
.mobile-menu-link:link,
.mobile-menu-link:visited {
    display: inline-flex;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: 0.18s;
}

.mobile-menu-link:hover {
    color: #75E8F0;
}

.touch .mobile-menu-link:hover {
    color: #FFFFFF;
}

.mobile-menu-link:active,
.touch .mobile-menu-link:active {
    color: #75E8F0;
}

.mobile-menu-networks {
    padding-top: 16px;
}

/* INTRO */

.home-intro {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #503439;
}

.home-intro-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.home-intro-video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #4837AB;
    opacity: 0.3;
}

.home-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    transform: translateX(-50%) translateY(-50%);
    overflow: hidden;
}

.home-intro-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20%;
    padding-right: 20%;
    box-sizing: border-box;
}

.home-intro-caption {
    color: #FFFFFF;
    font-size: 80px;
    line-height: 80px;
    font-weight: 700;
    letter-spacing: -0.02em;
}

.home-intro-mask {
    position: relative;
    height: 86px;
    margin-top: 6px;
    overflow: hidden;
}

.home-intro-guide {
    visibility: hidden;
    box-sizing: border-box;
}

.home-intro-word {
    display: block;
    position: absolute;
    top: 80px;
    padding-bottom: 6px;
    box-sizing: border-box;
    color: #75E8F0;
}

.home-intro-word[data-show] {
    transform: translateY(-100%);
    transition: 0.5s transform ease-in-out;
}

.home-intro-word[data-up] {
    transform: translateY(-200%);
    transition: 0.5s transform ease-in-out;
}

.home-intro-text {
    width: 100%;
    max-width: 460px;
    padding-top: 12px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
}

.home-intro-text img {
    width: auto;
    height: 21px;
    margin-bottom: 4px;
    margin-right: 8px;
    vertical-align: bottom;
}

.home-intro-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    padding-left: 34px;
    padding-right: 34px;
    margin-top: 36px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: #624DE3;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 6px 12px 0px rgba(98, 77, 227, 0.35);
    transition: 0.18s;
}

.home-intro-button img {
    display: inline-block;
    width: auto;
    height: 13px;
    margin: 0 0 0;
    margin-top: 1px;
    margin-left: 10px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.home-intro-button:hover {
    background-color: #533AE0;
    box-shadow: 0 6px 20px 0px rgba(98, 77, 227, 0.75);
}

.touch .home-intro-button:hover {
    background-color: #624DE3;
    box-shadow: 0 6px 12px 0px rgba(98, 77, 227, 0.35);
}

.home-intro-button:active,
.touch .home-intro-button:active {
    background-color: #533AE0;
    box-shadow: 0 6px 20px 0px rgba(98, 77, 227, 0.75);
}

.home-intro-button:hover img {
    transform: translateX(4px);
}

.touch .home-intro-button:hover img {
    transform: translateX(0px);
}

.home-intro-button:active img,
.touch .home-intro-button:active img {
    transform: translateX(4px);
}

.home-pulse {
    animation-name: home-pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes home-pulse {
    0% {
        transform: scale(0.95);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}

.home-pulse-2 {
    animation-name: home-pulse-2;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

@keyframes home-pulse-2 {
    0% {
        box-shadow: 0 6px 20px 0px rgba(98, 77, 227, 0.85);
    }

    50% {
        box-shadow: 0 6px 12px 0px rgba(98, 77, 227, 0.35);
    }

    100% {
        box-shadow: 0 6px 20px 0px rgba(98, 77, 227, 0.85);
    }
}

/* MARQUEE */

.home-marquee {
    display: flex;
    flex-direction: column;
    gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
    max-width: 100vw;
    padding-top: 82px;
    padding-bottom: 100px;
    margin: auto;
    box-sizing: border-box;
}

.home-marquee-title {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    text-align: center;
    font-size: 36px;
    line-height: 44px;
    color: #9748A3;
    font-weight: 600;
}

.home-marquee-frame {
    display: flex;
    gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
    box-sizing: border-box;
    overflow: hidden;
    mask-image: linear-gradient(to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 20%, hsl(0 0% 0% / 1) 80%, hsl(0 0% 0% / 0));
    -webkit-mask-image: linear-gradient(to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 20%, hsl(0 0% 0% / 1) 80%, hsl(0 0% 0% / 0));
}

.home-marquee-logos {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
    min-width: 100%;
    box-sizing: border-box;
    animation: intro-marquee-anima 60s linear infinite;
}

.home-marquee-frame-reverse .home-marquee-logos {
    animation-direction: reverse;
    animation-delay: -3s;
}

@keyframes intro-marquee-anima {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(-100% - calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14)));
    }
}

.home-marquee-logos div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(10rem, 1rem + 40vmin, 30rem);
    padding: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 10);
    box-sizing: border-box;
    border-radius: 0.5rem;
    aspect-ratio: 16/9;
    background: #EBDAF3;
}

.home-marquee-logos div img {
    display: block;
    width: 100%;
}

/* CHANNELS */

.home-channels {
    background-color: #FFFFFF;
    padding-top: 90px;
    padding-bottom: 120px;
}

.home-channels-title {
    width: 100%;
    max-width: 620px;
    margin: 0 auto;
    padding-bottom: 64px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    text-align: center;
    font-size: 36px;
    line-height: 44px;
    color: #32177A;
    font-weight: 600;
}

.home-channels-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    max-width: 1240px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    margin: 0 auto;
    column-gap: 32px;
    row-gap: 64px;
}

.home-channel {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
}

.home-channel-pic {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-channel-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 124px;
    height: 124px;
    border-radius: 500px;
    background-color: rgba(209, 99, 171, 0.1);
    box-shadow: 3px 3px 20px 0px rgba(209, 99, 171, 0.5);
}

.home-channel-pulse {
    animation-name: home-channel-pulse;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

@keyframes home-channel-pulse {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.9);
    }
}

.home-channel-pulse-2 {
    animation-name: home-channel-pulse-2;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

@keyframes home-channel-pulse-2 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.home-channel-icon img {
    display: block;
    width: 116px;
    height: 116px;
}

.home-channel-info {
    text-align: center;
    color: #32177A;
}

.home-channel-title {
    padding-bottom: 14px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.home-channel-caption {
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    color: #65519B;
}

.home-channel-button-frame {
    display: flex;
    justify-content: center;
    padding-top: 72px;
}

.home-channel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    padding-bottom: 2px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: #32177A;
    color: #75E8F0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 6px 12px 0px rgba(50, 23, 122, 0.35);
    transition: 0.18s;
}

.home-channel-button:hover {
    background-color: #4D23BA;
    box-shadow: 0 6px 20px 0px rgba(50, 23, 122, 0.75);
}

.touch .home-channel-button:hover {
    background-color: #32177A;
    box-shadow: 0 6px 12px 0px rgba(50, 23, 122, 0.35);
}

.home-channel-button:active,
.touch .home-channel-button:active {
    background-color: #4D23BA;
    box-shadow: 0 6px 20px 0px rgba(50, 23, 122, 0.75);
}

.home-channel-button img {
    display: inline-block;
    width: auto;
    height: 10px;
    margin: 0 0 0;
    margin-top: 2px;
    margin-left: 8px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

/* ENTITIES */

.home-entities {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #E8FEFF;
}

.home-entities-title {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    text-align: center;
    font-size: 36px;
    line-height: 44px;
    color: #624DE3;
    font-weight: 600;
}

.home-entities-swiper {
    width: 100%;
    padding-left: 24px !important;
    padding-right: 24px !important;
    margin: 0 auto;
    box-sizing: border-box;
    height: auto !important;
    padding-top: 54px !important;
    padding-bottom: 54px !important;
}

.home-entities-slide {
    width: 33.33333333333333% !important;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
}

.home-entity {
    display: flex;
    flex-direction: column;
    padding: 32px;
    padding-top: 48px;
    padding-bottom: 56px;
    box-sizing: border-box;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 18px;
    box-shadow: 0px 0px 54px 0px rgba(98, 77, 227, 0.15);
}

.home-entity-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
}

.home-entity-icon img {
    display: block;
    width: 90px;
    height: 90px;
}

.home-entity-floating {
    animation-name: home-entity-floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes home-entity-floating {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(8%);
    }

    100% {
        transform: translateY(0%);
    }
}

.home-entity-title {
    color: #624DE3;
    padding-bottom: 14px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.home-entity-content {
    color: #8171E9;
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
}

.home-entity-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    margin-top: 28px;
    padding-bottom: 2px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: #4837AB;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 6px 12px 0px rgba(72, 55, 171, 0.35);
    transition: 0.18s;
}

.home-entity-button:hover {
    background-color: #5F4BDE;
    box-shadow: 0 6px 20px 0px rgba(72, 55, 171, 0.75);
}

.touch .home-entity-button:hover {
    background-color: #4837AB;
    box-shadow: 0 6px 12px 0px rgba(72, 55, 171, 0.35);
}

.home-entity-button:active,
.touch .home-entity-button:active {
    background-color: #5F4BDE;
    box-shadow: 0 6px 20px 0px rgba(72, 55, 171, 0.75);
}

.home-entity-button img {
    display: inline-block;
    width: auto;
    height: 10px;
    margin: 0 0 0;
    margin-top: 2px;
    margin-left: 8px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.swiper-pagination-horizontal {
    bottom: 0px !important;
}

.swiper-pagination-bullet {
    transition: 0.25s;
    background-color: #624DE3 !important;
}

.swiper-pagination-bullet-active {
    background-color: #624DE3 !important;
}

/* PAYMENTS */

.home-payments {
    padding-top: 100px;
    padding-bottom: 120px;
    background-color: #FFFFFF;
}

.home-payments-caption {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    color: #D163AB;
    font-weight: 500;
}

.home-payments-caption strong {
    font-weight: 700;
}

.home-payments-cards {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding-top: 48px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    text-align: center;
}

.home-payments-card {
    display: inline-block;
    width: 72px;
    height: 72px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: #FFFFFF;
    border-radius: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 3px 3px 20px 0px rgba(209, 99, 171, 0.5);
}

.home-payments-pulse {
    animation-name: home-payments-pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes home-payments-pulse {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.9);
    }
}

.home-payments-pulse-2 {
    animation-name: home-payments-pulse-2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes home-payments-pulse-2 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.home-payments-card:nth-child(1) {
    background-image: url(../src/assets/visa.svg);
}

.home-payments-card:nth-child(2) {
    background-image: url(../src/assets/mastercard.svg);
}

.home-payments-card:nth-child(3) {
    background-image: url(../src/assets/amex.svg);
}

.home-payments-card:nth-child(4) {
    background-image: url(../src/assets/naranjax.svg);
}

.home-payments-card:nth-child(5) {
    background-image: url(../src/assets/cabal.svg);
}

/* PRICES */

.home-prices {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 80px;
    background-color: #4837AB;
}

.home-prices-title {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    text-align: center;
    font-size: 36px;
    line-height: 44px;
    color: #75E8F0;
    font-weight: 600;
}

.home-prices-switch {
    display: flex;
    column-gap: 1px;
    width: 284px;
    height: 44px;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 100px;
    box-shadow: 0 6px 12px 0px rgba(98, 77, 227, 0.6);
}

.home-prices-switch-option {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    width: 50%;
    box-sizing: border-box;
    background-color: #624DE3;
    color: rgba(255, 255, 255, 0.4);
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.18s;
}

.home-prices-switch-option img {
    display: block;
    height: 20px;
    opacity: 0.4;
    transition: 0.18s;
}

.home-prices-switch-option:nth-child(1) {
    padding-left: 2px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.home-prices-switch-option:nth-child(2) {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

.home-prices-switch-option-on {
    background-color: #6954F5 !important;
    color: #FFFFFF !important;
}

.home-prices-switch-option-on img {
    opacity: 1 !important;
}

.home-prices-switch-option:hover {
    color: #FFFFFF;
}

.touch .home-prices-switch-option:hover {
    color: rgba(255, 255, 255, 0.4);
}

.home-prices-switch-option:active,
.touch .home-prices-switch-option:active {
    color: #FFFFFF;
}

.home-prices-switch-option:hover img {
    opacity: 1;
}

.touch .home-prices-switch-option:hover img {
    opacity: 0.4;
}

.home-prices-switch-option:active img,
.touch .home-prices-switch-option:active img {
    opacity: 1;
}

.home-prices-grid {
    width: 100%;
    max-width: 1920px;
    display: none;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 32px;
    row-gap: 32px;
    padding-top: 44px;
    padding-bottom: 54px;
    padding-left: 48px;
    padding-right: 48px;
    margin: 0 auto;
    box-sizing: border-box;
    opacity: 0;
}

.home-prices-grid-alt {
    grid-template-columns: 1fr 1fr 1fr;
}

.home-prices-grid-on {
    display: grid;
    animation: home-prices-grid-anima 0.5s ease-in-out forwards;
}

@keyframes home-prices-grid-anima {
    0% {
        transform: scale(0.95);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.home-prices-item {
    display: flex;
    flex-direction: column;
    border: solid 2px #75E8F0;
    padding: 32px;
    padding-top: 48px;
    padding-bottom: 48px;
    box-sizing: border-box;
    text-align: center;
    box-sizing: border-box;
    border-radius: 18px;
    background: #4837AB;
    background: -moz-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: -webkit-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    box-shadow: 0px 0px 18px 0px rgba(72, 55, 171, 0.5);
}

.home-prices-info {
    flex: auto;
}

.home-prices-heading {
    padding-bottom: 11px;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.home-prices-percent {
    color: #75E8F0;
    font-size: 44px;
    line-height: 44px;
    font-weight: 700;
}

.home-prices-note {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    padding-top: 6px;
}

.home-prices-content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 20px;
    color: #FFFFFF;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
}

.home-prices-list-blue {
    color: #75E8F0;
}

.home-prices-list img {
    display: inline-block;
    width: auto;
    height: 10px;
    margin: 0 0 0;
    margin-top: -1px;
    margin-right: 8px;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
}

.home-prices-action {
    flex: none;
    padding-top: 28px;
}

.home-prices-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    padding-bottom: 2px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: #624DE3;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 6px 12px 0px rgba(98, 77, 227, 0.35);
    transition: 0.18s;
}

.home-prices-button:hover {
    background-color: #6B55FC;
    box-shadow: 0 6px 20px 0px rgba(98, 77, 227, 0.85);
}

.touch .home-prices-button:hover {
    background-color: #624DE3;
    box-shadow: 0 6px 12px 0px rgba(98, 77, 227, 0.35);
}

.home-prices-button:active,
.touch .home-prices-button:active {
    background-color: #6B55FC;
    box-shadow: 0 6px 20px 0px rgba(98, 77, 227, 0.85);
}

.home-prices-button img {
    display: inline-block;
    width: auto;
    height: 10px;
    margin: 0 0 0;
    margin-top: 2px;
    margin-left: 8px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

/* CONTACT */

.home-whatsapp-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: 0.18s;
}

.home-whatsapp-btn img {
    display: block;
    width: 100%;
    height: 100%;
}

.home-whatsapp-btn:hover {
    box-shadow: 0px 0px 0px 5px rgba(65, 229, 96, 0.35);
}

.touch .home-whatsapp-btn:hover {
    box-shadow: none;
}

.home-whatsapp-btn:active,
.touch .home-whatsapp-btn:active {
    box-shadow: 0px 0px 0px 5px rgba(65, 229, 96, 0.35);
}

.home-contact-title {
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: solid 2px #9748A3;
    color: #D163AB;
    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: center;
}

.home-contact-caption {
    padding: 24px;
    margin-top: 32px;
    margin-bottom: 24px;
    box-sizing: border-box;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    background: #624DE3;
    background: -moz-linear-gradient(45deg, #9748A3 0%, #624DE3 100%);
    background: -webkit-linear-gradient(45deg, #9748A3 0%, #624DE3 100%);
    background: linear-gradient(45deg, #9748A3 0%, #624DE3 100%);
    box-shadow: 0px 0px 24px 0px rgba(209, 99, 171, 0.25);
}

.home-contact-caption>div {
    padding-top: 8px;
}

.home-contact-caption strong {
    font-weight: 600;
}

.home-contact-caption img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-top: -64px;
}

.home-faqs {
    display: flex;
    flex-direction: column;
}

.home-faqs-block {
    padding-top: 11px;
    padding-bottom: 13px;
    border-bottom: solid 1px #EEEBFD;
}

.home-faqs-topic {
    color: #783981;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.18s;
}

.home-faqs-topic:hover {
    color: #9748A3;
}

.touch .home-faqs-topic:hover {
    color: #783981;
}

.home-faqs-topic:active,
.touch .home-faqs-topic:active {
    color: #9748A3;
}

.home-faqs-content {
    color: #9748A3;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    opacity: 0;
    animation-name: home-faqs-content-show;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes home-faqs-content-show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* FOOTER */

.footer {
    padding-top: 90px;
    padding-bottom: 64px;
    background: #9748A3;
    background: -moz-linear-gradient(45deg, #9748A3 0%, #624DE3 100%);
    background: -webkit-linear-gradient(45deg, #9748A3 0%, #624DE3 100%);
    background: linear-gradient(45deg, #9748A3 0%, #624DE3 100%);
}

.footer-networks-title {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    margin: 0 auto;
    box-sizing: border-box;
    color: #FFFFFF;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    text-align: center;
}

.footer-networks-links {
    display: flex;
    justify-content: center;
    padding-top: 48px;
    padding-left: 32px;
    padding-right: 32px;
}

.footer-divisor {
    width: 100%;
    max-width: 1260px;
    padding-left: 32px;
    padding-right: 32px;
    margin: 0 auto;
    margin-top: 110px;
    box-sizing: border-box;
}

.footer-divisor div {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.3);
}

.footer-layout {
    display: grid;
    grid-template-columns: 1fr 1.2fr 0.8fr;
    column-gap: 32px;
    row-gap: 32px;
    width: 100%;
    max-width: 1260px;
    padding-left: 32px;
    padding-right: 32px;
    margin: 0 auto;
    margin-top: 64px;
    box-sizing: border-box;
}

.footer-title {
    padding-top: 10px;
    padding-bottom: 26px;
    color: #FFFFFF;
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
}

.footer-brand {
    padding-bottom: 16px;
}

.footer-brand-link {
    display: inline-block;
}

.footer-brand img {
    display: block;
    height: 38px;
}

.footer-caption {
    color: #00234B;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
}

.footer-map {
    width: 100%;
    height: 230px;
    margin-top: 2px;
    margin-bottom: 20px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 18px 0px rgba(72, 55, 171, 0.5);
}

.footer-gmap {
    width: 100%;
    height: calc(100% + 24px);
}

.footer-contact-link,
.footer-contact-link:link,
.footer-contact-link:visited {
    display: inline-flex;
    margin-bottom: 8px;
    color: #00234B;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-decoration: none;
    transition: 0.18s;
}

.footer-contact-link img {
    display: block;
    width: 26px;
    height: 26px;
    transition: 0.18s;
}

.footer-contact-link div:nth-child(1) {
    padding-right: 10px;
}

.footer-contact-link div:nth-child(2) {
    display: flex;
    align-items: center;
}

.footer-contact-link:hover {
    color: #75E8F0;
}

.touch .footer-contact-link:hover {
    color: #00234B;
}

.footer-contact-link:active,
.touch .footer-contact-link:active {
    color: #75E8F0;
}

.footer-contact-link:hover img {
    transform: scale(1.25);
}

.touch .footer-contact-link:hover img {
    transform: scale(1);
}

.footer-contact-link:active img,
.touch .footer-contact-link:active img {
    transform: scale(1.25);
}

.footer-nav-link,
.footer-nav-link:link,
.footer-nav-link:visited {
    display: inline-flex;
    margin-bottom: 4px;
    color: #00234B;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: 0.18s;
}

.footer-nav-link img {
    display: block;
    height: 9px;
    transition: 0.18s;
}

.footer-nav-link div:nth-child(1) {
    display: flex;
    align-items: center;
    padding-right: 6px;
}

.footer-nav-link div:nth-child(2) {
    display: flex;
    align-items: center;
}

.footer-nav-link:hover {
    color: #75E8F0;
}

.touch .footer-nav-link:hover {
    color: #00234B;
}

.footer-nav-link:active,
.touch .footer-nav-link:active {
    color: #75E8F0;
}

.footer-nav-link:hover img {
    transform: scale(1.35);
}

.touch .footer-nav-link:hover img {
    transform: scale(1);
}

.footer-nav-link:active img,
.touch .footer-nav-link:active img {
    transform: scale(1.35);
}

.footer-copy {
    width: 100%;
    max-width: 1260px;
    padding-top: 84px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    box-sizing: border-box;
    color: #00234B;
    font-size: 14px;
    line-height: 20px;
}

.footer-copy strong {
    font-weight: 700;
}

/* BLOG */

.blog-intro {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.blog-intro-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222222;
}

.blog-intro-anima {
    width: 100%;
    height: 100%;
    filter: blur(64px);
    background-image: linear-gradient(rgba(55, 235, 169, 0.85), #5B37EB);
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    animation: blog-intro-rotate 5s cubic-bezier(0.8, 0.2, 0.2, 0.8) alternate infinite;
}

@keyframes blog-intro-rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.blog-intro-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
}

.blog-intro-content>div {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.blog-intro-title {
    color: #FFFFFF;
    font-size: 80px;
    line-height: 88px;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: center;
}

.blog-intro-title strong {
    color: #75E8F0;
}

.blog-intro-caption {
    width: 100%;
    padding-top: 32px;
    color: #75E8F0;
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    text-align: center;
}

.blog-intro-action {
    display: flex;
    justify-content: center;
}

.blog-intro-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    padding-left: 34px;
    padding-right: 34px;
    margin-top: 36px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: #533AE0;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 6px 12px 0px rgba(83, 58, 224, 0.35);
    cursor: pointer;
    transition: 0.18s;
}

.blog-intro-button img {
    display: inline-block;
    width: auto;
    height: 13px;
    margin: 0 0 0;
    margin-top: 1px;
    margin-left: 10px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.blog-intro-button:hover {
    box-shadow: 0 6px 20px 0px rgba(83, 58, 224, 0.75);
}

.touch .blog-intro-button:hover {
    box-shadow: 0 6px 12px 0px rgba(83, 58, 224, 0.35);
}

.blog-intro-button:active,
.touch .blog-intro-button:active {
    box-shadow: 0 6px 20px 0px rgba(83, 58, 224, 0.75);
}

.blog-intro-button:hover img {
    transform: translateX(4px);
}

.touch .blog-intro-button:hover img {
    transform: translateX(0px);
}

.blog-intro-button:active img,
.touch .blog-intro-button:active img {
    transform: translateX(4px);
}

.blog-title {
    width: 100%;
    max-width: 720px;
    padding-top: 72px;
    padding-bottom: 72px;
    padding-left: 32px;
    padding-right: 32px;
    margin: 0 auto;
    box-sizing: border-box;
    color: #32177A;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    text-align: center;
}

.blog-title-alt {
    color: #4837AB;
}

.blog-articles-frame {
    padding-bottom: 130px;
}

.blog-articles-frame-alt {
    background: #EBDAF3;
    background: -moz-linear-gradient(45deg, #EBDAF3 0%, #E8FEFF 100%);
    background: -webkit-linear-gradient(45deg, #EBDAF3 0%, #E8FEFF 100%);
    background: linear-gradient(45deg, #EBDAF3 0%, #E8FEFF 100%);
}

.blog-articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 30px;
    column-gap: 30px;
    width: 100%;
    max-width: 1240px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0 auto;
    box-sizing: border-box;
}

.blog-article,
.blog-article:link,
.blog-article:visited {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 20px;
    color: #32177A;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    box-shadow: 6px 6px 54px 0px rgba(50, 23, 122, 0.07);
    cursor: pointer;
    transition: 0.18s;
}

.blog-article:hover {
    box-shadow: 6px 6px 54px 0px rgba(50, 23, 122, 0.35);
}

.touch .blog-article:hover {
    box-shadow: 6px 6px 54px 0px rgba(50, 23, 122, 0.07);
}

.blog-article:active,
.touch .blog-article:active {
    box-shadow: 6px 6px 54px 0px rgba(50, 23, 122, 0.35);
}

.blog-article>div:nth-child(1) {
    flex: none;
}

.blog-article>div:nth-child(2) {
    flex: auto;
}

.blog-article>div:nth-child(3) {
    flex: none;
    padding-top: 16px;
}

.blog-article-picture {
    width: 100%;
    padding-bottom: 75%;
    background-color: #D7D0FF;
    border-radius: 16px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.18s;
}

.blog-article:hover .blog-article-picture {
    filter: brightness(93%);
}

.touch .blog-article:hover .blog-article-picture {
    filter: brightness(100%);
}

.blog-article:active .blog-article-picture,
.touch .blog-article:active .blog-article-picture {
    filter: brightness(93%);
}

.blog-article-tagline {
    padding-top: 17px;
    padding-bottom: 5px;
    padding-left: 2px;
    padding-right: 2px;
    box-sizing: border-box;
    color: #9748A3;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
}

.blog-article-title {
    padding-left: 2px;
    padding-right: 2px;
    box-sizing: border-box;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

.blog-article-button {
    padding-left: 2px;
    font-size: 14px;
    line-height: 18px;
}

.blog-article-button img {
    display: inline-block;
    width: auto;
    height: 10px;
    margin: 0 0 0;
    margin-top: 0;
    margin-left: 4px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    opacity: 0.6;
    transition: 0.18s;
}

.blog-article:hover .blog-article-button img {
    transform: translateX(4px);
    opacity: 1;
}

.touch .blog-article:hover .blog-article-button img {
    transform: translateX(0px);
    opacity: 0.6;
}

.blog-article:active .blog-article-button img,
.touch .blog-article:active .blog-article-button img {
    transform: translateX(4px);
    opacity: 1;
}

/* ARTICLE */

.article-intro {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 75%;
    overflow: hidden;
}

.article-intro-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #12092B;
}

.article-intro-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top, rgba(18, 9, 43, 0) 0%, rgba(18, 9, 43, 0.8) 100%);
    background: -webkit-linear-gradient(top, rgba(18, 9, 43, 0) 0%, rgba(18, 9, 43, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(18, 9, 43, 0) 0%, rgba(18, 9, 43, 0.8) 100%);
}

.article-intro-picture {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.article-intro-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
}

.article-intro-content>div {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.article-intro-tagline {
    padding-top: 64px;
    padding-bottom: 10px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0px 0px 16px rgba(18, 9, 43, 0.25);
}

.article-intro-title {
    color: #FFFFFF;
    font-size: 54px;
    line-height: 64px;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: center;
    text-shadow: 0px 0px 16px rgba(18, 9, 43, 0.25);
}

.article-intro-date {
    padding-top: 24px;
    padding-bottom: 32px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0px 0px 16px rgba(18, 9, 43, 0.25);
}

.article-intro-author {
    font-weight: 600;
}

.article-content {
    display: block;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 100px;
    background-color: #FFFFFF;
}

.article-content h2,
.article-content h3,
.article-content p,
.article-content ul,
.article-content ol,
.article-content-summary,
.article-image,
.article-content-embed {
    display: block;
    width: 100%;
    max-width: 800px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 48px;
    padding-right: 48px;
    margin: 0 auto;
    box-sizing: border-box;
}

.article-content-embed iframe {
    display: block !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.article-content-summary {
    padding-bottom: 22px;
    color: #41319C;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
}

.article-content p,
.article-content ul,
.article-content ol {
    color: #41319C;
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
}

.article-content ul,
.article-content ol {
    padding-left: 96px;
    padding-right: 96px;
    box-sizing: border-box;
}

.article-content li {
    padding: 0;
    margin: 0;
}

.article-content h2 {
    padding-top: 20px;
    color: #41319C;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}

.article-content h3 {
    padding-top: 20px;
    color: #9748A3;
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
}

.article-content a,
.article-content a:link,
.article-content a:visited {
    color: #9748A3;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.article-content a:hover {
    text-decoration: underline;
}

.touch .article-content a:hover {
    text-decoration: none;
}

.article-content a:active,
.touch .article-content a:active {
    text-decoration: underline;
}

.cdx-marker {
    color: #41319C !important;
    background-color: #D5F8FA !important;
    border-radius: 4px;
}

.article-image {
    padding-top: 24px;
    padding-bottom: 24px;
}

.article-content img {
    display: block;
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    border-radius: 4px;
}

.article-image-full {
    width: 100%;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
}

.article-image-full img {
    width: 100%;
    max-width: none;
    border-radius: 0;
}

.article-image-with-border img {
    border: solid 2px #E4E0FB;
    box-sizing: border-box;
}

.article-image-with-bg img {
    padding: 32px;
    background-color: #E4E0FB;
    box-sizing: border-box;
}

.article-content figcaption {
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    color: #41319C;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    text-align: center;
    opacity: 0.7;
}

/* NOT FOUND */

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 24px;
    padding-bottom: 64px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    color: #D163AB;
    text-align: center;
}

.not-found strong {
    display: block;
    padding-bottom: 16px;
    font-size: 48px;
    line-height: 48px;
}

/* ENTRANCE */

.entrance {
    display: flex;
    width: 100%;
    height: 100%;
}

.entrance-intro {
    position: relative;
    width: 40%;
    background-color: #624DE3;
    overflow: hidden;
}

.entrance-intro-caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    color: #FFFFFF;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.entrance-intro-caption strong {
    font-weight: 700;
}

.entrance-intro-pic-frame {
    position: absolute;
    left: 50%;
    bottom: -4%;
    width: 120%;
}

.entrance-intro-picture {
    position: relative;
    left: -50%;
    width: 100%;
    padding-bottom: 79.5%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../src/assets/entrance.png);
    animation-name: entrance-intro-floating;
    animation-duration: 6s;
    animation-iteration-count: infinite;
}

@keyframes entrance-intro-floating {
    0% {
        transform: translateY(0) scale(1);
    }

    50% {
        transform: translateY(2%) scale(0.99);
    }

    100% {
        transform: translateY(0) scale(1);
    }
}

.entrance-intro-elements {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.entrance-intro-picture img {
    display: block;
    position: absolute;
}

.entrance-intro-element-1 {
    width: 13.69791666666667%;
    top: 50.74428859047164%;
    left: 44.02545833333333%;
    animation: entrance-anima-3 1.5s infinite;
}

.entrance-intro-element-2 {
    width: 1.436770833333333%;
    top: 64.13156749754683%;
    left: 16.618828125%;
    animation: entrance-anima-1 6s linear infinite;
}

.entrance-intro-element-3 {
    width: 2.1369375%;
    top: 37.8639040333384%;
    left: 23.11843229166667%;
    animation: entrance-anima-2 6s linear infinite;
}

.entrance-intro-element-4 {
    width: 2.58703125%;
    top: 24.97013690264876%;
    left: 33.36690625%;
    animation: entrance-anima-1 6s linear infinite;
}

.entrance-intro-element-5 {
    width: 2.1800625%;
    top: 35.48064249093261%;
    left: 77.88645833333333%;
    animation: entrance-anima-2 6s linear infinite;
}

.entrance-intro-element-6 {
    width: 1.746614583333333%;
    top: 48.08780822134167%;
    left: 85.95985416666667%;
    animation: entrance-anima-1 6s linear infinite;
}

.entrance-intro-element-7 {
    width: 2.06084375%;
    top: 62.35442252040937%;
    left: 80.36184895833333%;
    animation: entrance-anima-2 6s linear infinite;
}

@keyframes entrance-anima-1 {
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(180deg) scale(1.5);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes entrance-anima-2 {
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(-180deg) scale(1.25);
    }

    100% {
        transform: rotate(-360deg) scale(1);
    }
}

@keyframes entrance-anima-3 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.18);
    }

    100% {
        transform: scale(1);
    }
}

.entrance-content {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
}

.entrance-bar {
    flex: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 98px;
    background-color: #FFFFFF;
}

.entrance-logo {
    display: block;
    width: 122px;
    padding-top: 4px;
}

.entrance-back {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.18s;
}

.entrance-back img {
    display: block;
    height: 14px;
}

.entrance-back:hover {
    opacity: 1;
}

.touch .entrance-back:hover {
    opacity: 0.5;
}

.entrance-back:active,
.touch .entrance-back:active {
    opacity: 1;
}

.entrance-steps {
    flex: auto;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    text-align: center;
    overflow: auto;
}

.entrance-step {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}

.entrance-caption {
    color: #41319C;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    padding-bottom: 56px;
}

.entrance-caption-small {
    padding-bottom: 24px;
}

.entrance-caption strong {
    font-weight: 700;
}

.entrance-caption-note {
    padding-top: 16px;
    color: #41319C;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.entrance-box-frame {
    position: relative;
    padding-bottom: 16px;
}

.entrance-box {
    width: 100%;
    height: 50px;
    padding: 0;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 1px;
    margin: 0;
    box-sizing: border-box;
    border-style: none;
    border-radius: 100px;
    background-color: #FFFFFF;
    color: #41319C;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0px 0px 0px 0px rgba(209, 99, 171, 0.5);
}

.entrance-box-password {
    padding-left: 50px;
    padding-right: 50px;
}

.entrance-box::placeholder {
    color: #41319C;
    font-weight: 500;
    opacity: 0.5;
}

.entrance-box:focus {
    animation: entrance-box-focus 0.5s ease-in-out forwards;
}

@keyframes entrance-box-focus {
    to {
        box-shadow: 0px 0px 70px 25px rgba(209, 99, 171, 0);
    }
}

.entrance-box-visibility {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
}

.entrance-box-visibility-on {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    transition: 0.18s;
}

.entrance-box-visibility-off {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    transition: 0.18s;
}

.entrance-box-visibility img {
    display: block;
    width: 20px;
    padding-right: 5px;
    opacity: 0.5;
    transition: 0.18s;
}

.entrance-box-visibility:hover img {
    opacity: 1;
}

.touch .entrance-box-visibility:hover img {
    opacity: 0.5;
}

.entrance-box-visibility:active img,
.touch .entrance-box-visibility:active img {
    opacity: 1;
}

.entrance-box-visibility-hidden {
    opacity: 0;
    z-index: 1 !important;
}

.entrance-btn-primary {
    width: 100%;
    height: 50px;
    padding: 0;
    padding-left: 22px;
    padding-right: 22px;
    margin: 0;
    margin-top: 8px;
    box-sizing: border-box;
    border-style: none;
    border-radius: 100px;
    background-color: #D163AB;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
    transition: 0.18s;
}

.entrance-btn-primary img {
    display: inline-block;
    width: auto;
    height: 13px;
    margin: 0 0 0;
    margin-top: -2px;
    margin-left: 8px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.entrance-btn-primary:hover {
    background-color: #DB68B4;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75);
}

.touch .entrance-btn-primary:hover {
    background-color: #D163AB;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
}

.entrance-btn-primary:active,
.touch .entrance-btn-primary:active {
    background-color: #DB68B4;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75);
}

.entrance-btn-primary:hover img {
    transform: translateX(4px);
}

.touch .entrance-btn-primary:hover img {
    transform: translateX(0px);
}

.entrance-btn-primary:active img,
.touch .entrance-btn-primary:active img {
    transform: translateX(4px);
}

.entrance-btn-secondary {
    width: 100%;
    height: 50px;
    padding: 0;
    padding-left: 22px;
    padding-right: 22px;
    margin: 0;
    margin-top: 24px;
    box-sizing: border-box;
    border-style: none;
    border-radius: 100px;
    background-color: transparent;
    border: solid 1px rgba(209, 99, 171, 0.5);
    color: #D163AB;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.15);
    transition: 0.18s;
}

.entrance-btn-secondary:hover {
    border: solid 1px rgba(209, 99, 171, 1);
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.55);
}

.touch .entrance-btn-secondary:hover {
    border: solid 1px rgba(209, 99, 171, 0.5);
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.15);
}

.entrance-btn-secondary:active,
.touch .entrance-btn-secondary:active {
    border: solid 1px rgba(209, 99, 171, 1);
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.55);
}

.entrance-waiting {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0;
    padding-left: 22px;
    padding-right: 22px;
    margin: 0;
    margin-top: 24px;
    box-sizing: border-box;
    border-style: none;
    border-radius: 100px;
    background-color: #D163AB;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
    opacity: 0.5;
}

.entrance-help {
    display: inline-block;
    margin-top: 42px;
    color: #624DE3;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: 0.18s;
}

.entrance-help svg {
    display: inline-block;
    width: auto;
    height: 19px;
    margin: 0 0 0;
    margin-top: -2px;
    margin-left: 0;
    margin-right: 4px;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
}

.entrance-help circle {
    transition: 0.18s;
}

.entrance-help:hover {
    color: #DB68B4;
}

.touch .entrance-help:hover {
    color: #624DE3;
}

.entrance-help:active,
.touch .entrance-help:active {
    color: #DB68B4;
}

.entrance-help:hover circle {
    fill: #DB68B4;
}

.touch .entrance-help:hover circle {
    fill: #624DE3;
}

.entrance-help:active circle,
.touch .entrance-help:active circle {
    fill: #DB68B4;
}

/* DASHBOARD */

.dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-left: 258px;
    box-sizing: border-box;
}

.dashboard-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 80;
}

.dashboard-loading-caption {
    margin-top: 12px;
    color: #9748A3;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
}

.dashboard-spinner {
    display: block;
    margin: 0 auto;
    width: 54px;
    height: 54px;
    animation: dashboard-spinner-rotate 1.75s linear infinite;
}

.dashboard-spinner-path {
    stroke: #D163AB;
    stroke-linecap: round;
    animation: dashboard-spinner-dash 1.3125s ease-in-out infinite;
}

@keyframes dashboard-spinner-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dashboard-spinner-dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

.section {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    overflow-y: scroll;
}

/* OFFLINE */

.offline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EB3941;
    z-index: 110;
}

.offline-caption {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 12px;
    color: #FFFFFF;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
}

.offline-caption strong {
    font-weight: 600;
}

.offline-spinner {
    display: block;
    margin: 0 auto;
    width: 54px;
    height: 54px;
    animation: offline-spinner-rotate 6.75s linear infinite;
}

.offline-spinner-path {
    stroke: #FFFFFF;
    stroke-linecap: round;
    animation: offline-spinner-dash 6.75s ease-in-out infinite;
}

@keyframes offline-spinner-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes offline-spinner-dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

/* BAR */

.bar {
    flex: none;
    display: none;
    width: 100%;
    height: 60px;
    background-color: #624DE3;
    box-shadow: 0px 0px 18px -2px rgba(72, 55, 171, 0.5);
}

.bar-brand {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bar-brand-link {
    display: block;
}

.bar-brand-link img {
    display: block;
    height: 29px;
}

.bar-menu {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 69px;
    cursor: pointer;
}

.bar-menu:hover .bar-menu-line {
    background-color: #75E8F0;
}

.touch .bar-menu:hover .bar-menu-line {
    background-color: #FFFFFF;
}

.bar-menu:active .bar-menu-line,
.touch .bar-menu:active .bar-menu-line {
    background-color: #75E8F0;
}

.bar-menu-line {
    width: 26px;
    height: 2px;
    margin-bottom: 5px;
    border-radius: 20px;
    background-color: #FFFFFF;
    transition: 0.18s;
}

.bar-menu-line:last-of-type {
    margin-bottom: 0px;
}

.bar-account {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 69px;
    cursor: pointer;
}

/* PANEL */

.panel {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 258px;
    height: 100%;
    background: #4837AB;
    background: -moz-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: -webkit-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    box-shadow: 0px 0px 18px 0px rgba(72, 55, 171, 0.5);
    transition: 0.5s;
    z-index: 4;
}

.panel-opened {
    transition: 0.25s;
}

.panel-bg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(3, 2, 8);
    opacity: 0;
    z-index: 3;
    animation: panel-bg-anima 0.25s ease-in-out forwards;
}

@keyframes panel-bg-anima {
    to {
        opacity: 0.85;
    }
}

.panel-brand {
    flex: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 24px;
    padding-left: 28px;
}

.panel-brand-link {
    display: block;
}

.panel-brand-link img {
    display: block;
    width: 122px;
}

.panel-close {
    flex: none;
    display: none;
    justify-content: right;
}

.panel-close-btn {
    display: inline-flex;
    padding: 20px;
    cursor: pointer;
}

.panel-close-btn svg {
    display: block;
    width: 10px;
}

.panel-close-btn svg path {
    transition: 0.18s;
}

.panel-close-btn:hover svg path {
    fill: #75E8F0;
}

.touch .panel-close-btn:hover svg path {
    fill: #FFFFFF;
}

.panel-close-btn:active svg path,
.touch .panel-close-btn:active svg path {
    fill: #75E8F0;
}

.panel-nav {
    flex: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 32px;
    overflow: auto;
}

.panel-nav::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 30px;
}

.panel-nav::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #4837AB;
}

.panel-nav::-webkit-scrollbar-thumb:hover {
    background: #3E2F92;
}

.panel-nav::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 30px;
}

.panel-group {
    display: block;
    padding-top: 30px;
    padding-bottom: 16px;
    padding-left: 20px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 11px;
    line-height: 11px;
    font-weight: 700;
    text-transform: uppercase;
}

.panel-button,
.panel-button:link,
.panel-button:visited {
    display: flex;
    width: 100%;
    height: 44px;
    border-radius: 7px;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: 0.18s;
}

.panel-button-icon {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
}

.panel-button-icon svg {
    display: block;
    opacity: 0.7;
    transition: 0.18s;
}

.panel-button-label {
    flex: auto;
    display: flex;
    align-items: center;
    padding-right: 16px;
    box-sizing: border-box;
}

.panel-button:hover {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(72, 55, 171, 0.5);
}

.touch .panel-button:hover {
    color: rgba(255, 255, 255, 0.85);
    background-color: transparent;
}

.panel-button:active,
.touch .panel-button:active {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(72, 55, 171, 0.5);
}

.panel-button:hover svg {
    opacity: 1;
    transform: scale(1.28);
}

.touch .panel-button:hover svg {
    opacity: 0.7;
    transform: scale(1);
}

.panel-button:active svg,
.touch .panel-button:active svg {
    opacity: 1;
    transform: scale(1.28);
}

.panel-button-on,
.panel-button-on:link,
.panel-button-on:visited {
    color: rgba(255, 255, 255, 1);
    background-color: #4837AB;
}

.panel-button-on .panel-button-icon svg {
    opacity: 1;
    transform: scale(1.28);
}

.panel-button-on .panel-button-icon svg path {
    fill: #75E8F0;
}

.panel-account {
    flex: none;
    display: flex;
    border-top: solid 1px rgba(255, 255, 255, 0.25);
    cursor: pointer;
}

.panel-account-avatar {
    flex: none;
    padding-top: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
    padding-right: 12px;
}

.panel-account-name {
    flex: auto;
    display: flex;
    align-items: center;
    padding-bottom: 1px;
    color: #FFFFFF;
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
}

.panel-account-options {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.panel-account-options img {
    display: block;
    width: 16px;
    opacity: 0.5;
    transition: 0.18s;
}

.panel-account:hover .panel-account-options img {
    opacity: 1;
}

.touch .panel-account:hover .panel-account-options img {
    opacity: 0.5;
}

.panel-account:active .panel-account-options img,
.touch .panel-account:active .panel-account-options img {
    opacity: 1;
}

/* HEADER */

.header {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
}

.header-data {
    display: flex;
    width: 100%;
}

.header-info {
    flex: auto;
    padding-bottom: 11px;
}

.header-title {
    display: flex;
    color: #32177A;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.01rem;
}

.header-back-frame {
    padding-right: 16px;
}

.header-back,
.header-back:link,
.header-back:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 39px;
    height: 39px;
    padding: 10px;
    padding-right: 12px;
    margin-top: -4px;
    box-sizing: border-box;
    border-radius: 200px;
    border: solid 2px rgba(98, 77, 227, 0.25);
    cursor: pointer;
    transition: 0.18s;
}

.header-back img {
    display: block;
    height: 100%;
}

.header-back:hover {
    background-color: rgba(98, 77, 227, 0.25);
    border-color: transparent;
}

.touch .header-back:hover {
    background-color: transparent;
    border-color: rgba(98, 77, 227, 0.25);
}

.header-back:active,
.touch .header-back:active {
    background-color: rgba(98, 77, 227, 0.25);
    border-color: transparent;
}

.header-intro {
    padding-top: 9px;
    padding-bottom: 11px;
    padding-right: 20px;
    color: #6F5CA2;
    font-size: 16px;
    line-height: 22px;
}

.header-actions {
    flex: none;
}

/* START */

.content-focused {
    width: 100%;
    max-width: 840px;
    padding-top: 70px;
    padding-bottom: 64px;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0 auto;
    box-sizing: border-box;
}

.greeting {
    padding-top: 20px;
    padding-bottom: 12px;
    padding-left: 6px;
    color: #32177A;
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
}

.start-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 40px;
}

.box-white {
    display: flex;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 2px #624DE3;
    background-color: #FFFFFF;
    color: #624DE3;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 6px 6px 54px 0px rgba(98, 77, 227, 0.05);
    transition: 0.18s;
}

.box-white:hover {
    box-shadow: 6px 6px 54px 0px rgba(98, 77, 227, 0.35);
}

.touch .box-white:hover {
    box-shadow: 6px 6px 54px 0px rgba(98, 77, 227, 0.05);
}

.box-white:active,
.touch .box-white:active {
    box-shadow: 6px 6px 54px 0px rgba(98, 77, 227, 0.35);
}

.box-white-info {
    flex: 1;
    padding: 30px;
}

.box-white-title {
    padding-bottom: 8px;
    font-size: 17px;
    line-height: 23px;
    font-weight: 500;
    opacity: 0.75;
}

.box-white-amount {
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
}

.box-white-amount-alt {
    font-weight: 500;
    opacity: 0.5;
}

.box-white-pic {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: rgba(98, 77, 227, 0.2);
}

.box-white-pic img {
    display: block;
    width: 56px;
    opacity: 0.65;
}

.box-blue,
.box-blue:link,
.box-blue:visited {
    display: flex;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #7300FF;
    background: -moz-linear-gradient(left, #624DE3 0%, #4837AB 100%);
    background: -webkit-linear-gradient(left, #624DE3 0%, #4837AB 100%);
    background: linear-gradient(to right, #624DE3 0%, #4837AB 100%);
    color: #4837AB;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 6px 6px 54px 0px rgba(72, 55, 171, 0.3);
    transition: 0.18s;
}

.box-blue:hover {
    box-shadow: 6px 6px 54px 0px rgba(72, 55, 171, 0.6);
}

.touch .box-blue:hover {
    box-shadow: 6px 6px 54px 0px rgba(72, 55, 171, 0.3);
}

.box-blue:active,
.touch .box-blue:active {
    box-shadow: 6px 6px 54px 0px rgba(72, 55, 171, 0.6);
}

.box-blue:hover .box-blue-caption img {
    margin-left: 10px;
}

.touch .box-blue:hover .box-blue-caption img {
    margin-left: 7px;
}

.box-blue:active .box-blue-caption img,
.touch .box-blue:active .box-blue-caption img {
    margin-left: 10px;
}

.box-blue-info {
    flex: 1;
    padding-right: 16px;
}

.box-blue-title {
    padding-bottom: 8px;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.box-blue-caption {
    color: #FFFFFF;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    opacity: 0.75;
}

.box-blue-caption img {
    display: inline-block;
    width: auto;
    height: 10px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 7px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.box-blue-pic {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-blue-pic img {
    display: block;
    width: 70px;
}

/* SHORTCUTS */

.shortcuts {
    width: 100%;
    max-width: 640px;
    margin-top: 36px;
    margin-bottom: 24px;
    margin-left: -24px;
}

.shortcut,
.shortcut:link,
.shortcut:visited {
    display: inline-block;
    width: 110px;
    margin-bottom: 20px;
    margin-left: 8px;
    margin-right: 8px;
    color: #D163AB;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
}

.shortcut-caption {
    margin-top: 10px;
    white-space: nowrap;
}

.shortcut-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 74px;
    margin: 0 auto;
    border-radius: 100px;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 20px 0px rgba(209, 99, 171, 0.5);
    transition: 0.18s;
}

.shortcut-icon img {
    display: block;
    width: 52px;
    transition: 0.18s;
}

.shortcut:hover .shortcut-icon {
    box-shadow: 3px 3px 30px 0px rgba(209, 99, 171, 1);
}

.touch .shortcut:hover .shortcut-icon {
    box-shadow: 3px 3px 20px 0px rgba(209, 99, 171, 0.5);
}

.shortcut:active .shortcut-icon,
.touch .shortcut:active .shortcut-icon {
    box-shadow: 3px 3px 30px 0px rgba(209, 99, 171, 1);
}

.shortcut:hover img {
    transform: scale(1.5);
}

.touch .shortcut:hover img {
    transform: scale(1);
}

.shortcut:active img,
.touch .shortcut:active img {
    transform: scale(1.5);
}

/* NEWS */

.news {
    padding-top: 40px;
    padding-bottom: 40px;
}

.news-item {
    padding: 30px;
    padding-top: 24px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
}

.news-empty {
    color: #624DE3;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.news-title {
    padding-top: 4px;
    padding-bottom: 8px;
    color: #624DE3;
    font-size: 19px;
    line-height: 25px;
    font-weight: 500;
}

.news-tag {
    display: inline-block;
    padding: 3px 8px 3px 8px;
    margin-bottom: 9px;
    border-radius: 5px;
    background-color: rgba(209, 99, 171, 0.25);
    color: #D163AB;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.news-content {
    color: #624DE3;
    font-size: 15px;
    line-height: 20px;
    opacity: 0.75;
}

.news-content p {
    padding: 0;
    padding-bottom: 6px;
    margin: 0;
}

.news-content p:last-of-type {
    padding-bottom: 0px;
}

/* NETWORKS */

.networks {
    display: flex;
    row-gap: 16px;
    flex-wrap: wrap;
    column-gap: 16px;
    justify-content: center;
    justify-items: center;
    margin-top: 40px;
}

.networks-alt,
.networks-alt-2 {
    margin-top: 0px;
}

.networks-alt-4 {
    justify-content: flex-start;
    justify-items: flex-start;
}

.network,
.network:link,
.network:visited {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    box-shadow: 3px 3px 24px 0px rgba(0, 0, 0, 0.15);
    transition: 0.18s;
}

.networks-alt .network,
.networks-alt .network:link,
.networks-alt .network:visited,
.networks-alt-2 .network,
.networks-alt-2 .network:link,
.networks-alt-2 .network:visited {
    width: 64px;
    height: 64px;
}

.networks-alt-3 .network,
.networks-alt-3 .network:link,
.networks-alt-3 .network:visited {
    width: 40px;
    height: 40px;
}

.network img {
    display: block;
    width: 100%;
}

.network:hover {
    transform: scale(1.25);
}

.touch .network:hover {
    transform: scale(1);
}

.network:active,
.touch .network:active {
    transform: scale(1.25);
}

.network:nth-child(1) {
    box-shadow: 3px 3px 24px 0px rgba(35, 207, 67, 0.25);
}

.network:nth-child(2) {
    box-shadow: 3px 3px 24px 0px rgba(233, 54, 93, 0.25);
}

.network:nth-child(3) {
    box-shadow: 3px 3px 24px 0px rgba(28, 161, 241, 0.25);
}

.network:nth-child(4) {
    box-shadow: 3px 3px 24px 0px rgba(237, 53, 55, 0.25);
}

.network:nth-child(5) {
    box-shadow: 3px 3px 24px 0px rgba(0, 119, 181, 0.25);
}

.networks-alt-2 .network:nth-child(1) {
    box-shadow: 3px 3px 24px 0px rgba(35, 207, 67, 1);
}

.networks-alt-2 .network:nth-child(2) {
    box-shadow: 3px 3px 24px 0px rgba(233, 54, 93, 1);
}

.networks-alt-2 .network:nth-child(3) {
    box-shadow: 3px 3px 24px 0px rgba(28, 161, 241, 1);
}

.networks-alt-2 .network:nth-child(4) {
    box-shadow: 3px 3px 24px 0px rgba(237, 53, 55, 1);
}

.networks-alt-2 .network:nth-child(5) {
    box-shadow: 3px 3px 24px 0px rgba(0, 119, 181, 1);
}

/* BIG BUTTON */

.big-button,
.big-button:link,
.big-button:visited {
    display: flex;
    height: 46px;
    padding-left: 26px;
    padding-right: 26px;
    box-sizing: border-box;
    background-color: #D163AB;
    border-radius: 200px;
    color: #FFFFFF;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
    transition: 0.18s;
}

.big-button-icon {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 8px;
}

.big-button-icon img {
    display: block;
    width: 18px;
}

.big-button-caption {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    box-sizing: border-box;
}

.big-button:hover {
    transform: scale(1.1);
}

.touch .big-button:hover {
    transform: scale(1);
}

.big-button:active,
.touch .big-button:active {
    transform: scale(1.1);
}

/* ROUNDED BUTTON */

.rounded-button {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3px;
    height: 38px;
    padding-bottom: 2px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: rgb(var(--color));
    color: var(--textColor);
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    box-shadow: 0 6px 12px 0px rgba(var(--color), 0.35);
    cursor: pointer;
    transition: 0.18s;
}

.rounded-button:hover {
    background-color: rgb(var(--color));
    box-shadow: 0 6px 20px 0px rgba(var(--color), 0.75);
}

.touch .rounded-button:hover {
    background-color: rgb(var(--color));
    box-shadow: 0 6px 12px 0px rgba(var(--color), 0.35);
}

.rounded-button:active,
.touch .rounded-button:active {
    background-color: rgb(var(--color));
    box-shadow: 0 6px 20px 0px rgba(var(--color), 0.75);
}

.rounded-button-icon img {
    display: block;
    height: 16px;
}

/* TABS */

.tabs {
    position: relative;
    width: 100%;
    height: 62px;
    border-bottom: solid 1px rgba(151, 72, 163, 0.25);
}

.tabs-buttons {
    display: flex;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 100%;
}

.tab-button,
.tab-button:link,
.tab-button:visited {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 20px;
    box-sizing: border-box;
    border-bottom: solid 3px transparent;
    color: #32177A;
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    text-decoration: none;
    opacity: 0.45;
    transition: 0.18s;
}

.tab-label-long {
    display: inline;
}

.tab-label-short {
    display: none;
}

.tab-button:hover {
    opacity: 0.75;
    border-bottom-color: #32177A;
}

.touch .tab-button:hover {
    opacity: 1;
    border-bottom-color: transparent;
}

.tab-button:active,
.touch .tab-button:active {
    opacity: 0.75;
    border-bottom-color: #32177A;
}

.tab-button:last-child {
    margin-right: 0px;
}

.tab-button-on {
    opacity: 1 !important;
    border-bottom-color: #D163AB !important;
}

.tab-counter {
    display: inline-block;
    padding-left: 6px;
    color: rgba(209, 99, 171, 0.45);
    font-weight: 600;
    transition: 0.18s;
}

/* AVATAR */

.avatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 300px;
    background-color: #B7FAFF;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-decoration: none;
    overflow: hidden;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.17);
}

.avatar-capitals {
    width: 100%;
    height: 100%;
    padding: 0;
    padding-top: 5.5%;
    margin: 0;
    box-sizing: border-box;
    resize: both;
    font-family: "Varela Round", sans-serif;
    text-align: center;
    font-weight: 400;
    resize: none;
    overflow: hidden;
}

.avatar-capitals text {
    fill: #624DE3;
}

.avatar-entrance {
    width: 76px;
    height: 76px;
    margin: 0 auto;
    margin-bottom: 10px;
    box-shadow: none;
}

.avatar-panel {
    width: 44px;
    height: 44px;
    box-shadow: none;
}

.avatar-panel .avatar-capitals {
    padding-top: 6%;
}

.avatar-panel text {
    font-size: 40px;
}

.avatar-bar {
    width: 38px;
    height: 38px;
    box-shadow: none;
    transition: 0.18s;
}

.avatar-bar .avatar-capitals {
    padding-top: 6%;
}

.avatar-bar text {
    font-size: 40px;
}

.avatar-bar:hover {
    transform: scale(1.117);
}

.touch .avatar-bar:hover {
    transform: scale(1);
}

.avatar-bar:active,
.touch .avatar-bar:active {
    transform: scale(1.117);
}

.avatar-big {
    width: 86px;
    height: 86px;
}

.avatar-xbig {
    width: 72px;
    height: 72px;
}

.avatar-medium {
    width: 60px;
    height: 60px;
}

.avatar-small {
    width: 50px;
    height: 50px;
}

.avatar-card {
    width: 46px;
    height: 46px;
}

.avatar-payment {
    width: 36px;
    height: 36px;
    box-shadow: none;
}

.avatar-payment-shop {
    width: 60px;
    height: 60px;
    box-shadow: none;
}

.avatar-payment-success {
    width: 80px;
    height: 80px;
    box-shadow: none;
}

.avatar-unknown {
    display: block;
    width: 100%;
    height: 100%;
}

/* ICON */

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 300px;
    background-color: #9748A3;
}

.icon img {
    display: block;
    width: 100%;
    height: 100%;
}

.icon-medium {
    width: 60px;
    height: 60px;
}

.icon-small {
    width: 30px;
    height: 30px;
}

/* SYMBOL */

.symbol {
    width: 50px;
    height: 50px;
    background-color: #FFFFFF;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 5px 0px rgba(98, 77, 227, 0.3);
}

.symbol-small {
    width: 25px;
    height: 25px;
    box-shadow: 0px 0px 2px 0px rgba(98, 77, 227, 0.3);
}

.symbol-tiny {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    margin-top: -2px;
    margin-left: 2px;
    margin-right: 5px;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    box-shadow: 0px 0px 2px 0px rgba(98, 77, 227, 0.3);
}

.symbol-card {
    width: 46px;
    height: 46px;
}

.symbol-circle {
    border-radius: 500px;
}

.symbol-square {
    border-radius: 8px;
}

/* LIST */

.content-frame {
    padding: 40px;
    padding-top: 20px;
    padding-bottom: 48px;
    box-sizing: border-box;
}

.content-frame-cards {
    padding: 40px;
    padding-top: 40px;
    padding-bottom: 48px;
    box-sizing: border-box;
}

.content-frame-top {
    padding-top: 30px;
    padding-bottom: 0px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
}

.content-frame-top-single {
    padding-top: 10px;
    padding-bottom: 20px;
}

/* ITEMS */

.items-header {
    display: flex;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.75);
    border-bottom: solid 1px #EEEBFD;
    color: #978EC9;
    font-size: 14px;
    line-height: 14px;
}

.items-header-full {
    flex: auto;
    padding-left: 16px;
    box-sizing: border-box;
}

.items-header-1 {
    flex: none;
    min-width: 80px;
    text-align: right;
}

.items-header-2 {
    flex: none;
    min-width: 92px;
    text-align: right;
}

.items-header-3 {
    flex: none;
    min-width: 92px;
    text-align: right;
}

.items-header-analytics-1 {
    flex: auto;
    padding-left: 10px;
    box-sizing: border-box;
}

.items-header-analytics-2,
.items-header-analytics-3,
.items-header-analytics-4,
.items-header-analytics-5 {
    flex: none;
    min-width: 107px;
    text-align: center;
}

.items {
    display: block;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 54px 0px rgba(50, 23, 122, 0.05);
    overflow: hidden;
}

.items-empty {
    padding: 40px;
    box-sizing: border-box;
    border-radius: 11px;
    border: solid 2px #CCC6F8;
    background-color: transparent;
    color: #41319C;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
}

.items-empty img {
    display: block;
    width: 100px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.items-block {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    border-radius: 0;
    box-shadow: none;
    overflow: visible;
}

.item,
.item:link,
.item:visited {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-color: #FFFFFF;
    color: #41319C;
    font-size: 15px;
    line-height: 22px;
    text-decoration: none;
    transition: 0.18s;
}

.item:hover {
    background-color: #F7F4FF;
}

.touch .item:hover {
    background-color: #FFFFFF;
}

.item:active,
.touch .item:active {
    background-color: #F7F4FF;
}

.item-row {
    padding: 16px;
    border-bottom: solid 1px #EEEBFD;
}

.item-row:last-of-type {
    border-bottom-color: transparent;
}

.item-row-alt {
    padding: 10px;
    border-bottom: solid 1px #EEEBFD;
}

.item-row-transparent {
    padding-left: 32px;
    opacity: 0.6;
}

.item-block {
    display: flex;
    column-gap: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 0px 8px -1px rgba(50, 23, 122, 0.05);
}

.item-block:hover {
    box-shadow: 0px 0px 32px -1px rgba(50, 23, 122, 0.3);
}

.touch .item-block:hover {
    box-shadow: 0px 0px 8px -1px rgba(50, 23, 122, 0.05);
}

.item-block:active,
.touch .item-block:active {
    box-shadow: 0px 0px 32px -1px rgba(50, 23, 122, 0.3);
}

.item-off>div {
    opacity: 0.5;
}

.item-data {
    display: flex;
    align-items: center;
    column-gap: 16px;
    width: 100%;
    padding-bottom: 1px;
    padding-left: 16px;
    box-sizing: border-box;
}

.item-data-full {
    flex: auto;
}

.item-record-1 {
    flex: auto;
    align-items: flex-start;
    padding-left: 0px;
}

.item-record-2 {
    flex: none;
    display: flex;
    column-gap: 16px;
}

.item-data-center {
    display: flex;
    align-items: center;
}

.item-data-single {
    display: flex;
    align-items: center;
    padding-bottom: 0px;
    padding-left: 6px;
    padding-right: 6px;
    box-sizing: border-box;
}

.item-data-flex {
    display: flex;
    column-gap: 16px;
}

.item-block-icon {
    flex: none;
}

.item-block-data {
    flex: auto;
    display: flex;
    align-items: center;
    padding-bottom: 4px;
}

.item-point {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-bottom: 1px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 20px;
    background-color: #999999;
}

.item-point-green {
    background-color: #2DCA73;
}

.item-point-red {
    background-color: #EB3941;
}

.item-point-yellow {
    background-color: #F5BD00;
}

.item-point-orange {
    background-color: #FF7B00;
}

.item-green {
    color: #2DCA73;
}

.item-red {
    color: #EB3941;
}

.item-yellow {
    color: #F5BD00;
}

.item-orange {
    color: #FF7B00;
}

.item-title {
    padding-bottom: 2px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}

.item-align-right {
    text-align: right;
}

.item-justify-right {
    justify-content: flex-end;
}

.item-medium {
    font-weight: 500;
}

.item-semibold {
    font-weight: 600;
}

.item-bold {
    font-weight: 700;
}

.item-gray {
    color: #978EC9;
}

.item-violet {
    color: #9748A3;
}

.item-blue {
    color: #624DE3;
}

.item-small {
    font-size: 13px;
    line-height: 22px;
}

.item-xsmall {
    font-size: 12px;
    line-height: 22px;
}

.item-uppercase {
    text-transform: uppercase;
}

.item-button {
    display: inline-flex;
    cursor: pointer;
    transition: 0.18s;
}

.item-button:hover {
    text-decoration: underline;
}

.touch .item-button:hover {
    text-decoration: none;
}

.item-button:active,
.touch .item-button:active {
    text-decoration: underline;
}

.item-tag {
    display: inline-block;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 2px;
    border-radius: 5px;
    background-color: transparent;
    color: #D163AB;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.18s;
}

.item-tag:hover {
    background-color: rgba(209, 99, 171, 0.2);
}

.touch .item-tag:hover {
    background-color: transparent;
}

.item-tag:active,
.touch .item-tag:active {
    background-color: rgba(209, 99, 171, 0.2);
}

.liquidation {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 0;
    margin-top: -2px;
    margin-left: 0px;
    margin-right: 1px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: rgba(45, 202, 115, 0.12);
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.liquidation img {
    display: block;
    width: 16px;
}

.liquidation-button {
    display: inline-block;
    cursor: pointer;
}

.liquidation-button:hover .liquidation {
    transform: scale(1.25);
}

.touch .liquidation-button:hover .liquidation {
    transform: scale(1);
}

.liquidation-button:active .liquidation,
.touch .liquidation-button:active .liquidation {
    transform: scale(1.25);
}

.rejection {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 0;
    margin-top: -2px;
    margin-left: 2px;
    margin-right: 1px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: rgba(235, 57, 65, 0.12);
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.rejection img {
    display: block;
    width: 16px;
}

.rejection-button {
    display: inline-block;
    cursor: pointer;
}

.rejection-button:hover .rejection {
    transform: scale(1.25);
}

.touch .rejection-button:hover .rejection {
    transform: scale(1);
}

.rejection-button:active .rejection,
.touch .rejection-button:active .rejection {
    transform: scale(1.25);
}

/* SPOTLIGHT */

.spotlight {
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #FFFFFF;
    border: solid 2px #624DE3;
    box-shadow: 0px 0px 16px 0px rgba(50, 23, 122, 0.12);
}

.spotlight-rows {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    padding-top: 18px;
    padding-bottom: 0px;
}

.spotlight-rows-alt {
    row-gap: 10px;
    padding: 16px;
    padding-top: 16px;
    padding-bottom: 6px;
}

.spotlight-row {
    display: flex;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: solid 1px #DFD9FF;
}

.spotlight-rows-alt .spotlight-row {
    padding-bottom: 10px;
}

.spotlight-row:last-of-type {
    border-bottom-style: none;
}

.spotlight-row-detail {
    flex: auto;
    display: flex;
    align-items: center;
}

.spotlight-row-amount {
    flex: none;
}

.spotlight-title {
    margin-top: -3px;
    color: #41319C;
    font-size: 16px;
    line-height: 23px;
    font-weight: 600;
}

.spotlight-title img {
    display: inline-block;
    width: auto;
    height: 16px;
    margin: 0 0 0;
    margin-top: -0.3ex;
    margin-left: 0;
    margin-right: 6px;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
}

.spotlight-note {
    color: #41319C;
    font-size: 14px;
    line-height: 21px;
}

.spotlight-title-alt {
    color: #D163AB;
}

.spotlight-title-alt-2 {
    color: #9748A3;
}

.spotlight-note-alt {
    color: #D163AB;
}

.spotlight-button {
    padding-top: 8px;
}

/* AMOUNT */

.amount {
    display: flex;
    align-items: center;
    height: 36px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
    background-color: rgba(98, 77, 227, 0.12);
    color: #624DE3;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    white-space: nowrap;
}

.amount-transparent {
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
}

.amount-green {
    color: #2DCA73;
    background-color: rgba(45, 202, 115, 0.12);
}

.amount-green-alt {
    color: #2DCA73;
    background-color: transparent;
}

.amount-red {
    color: #EB3941;
    background-color: rgba(235, 57, 65, 0.12);
}

.amount-red-alt {
    color: #EB3941;
    background-color: transparent;
}

.amount-yellow {
    color: #F5BD00;
    background-color: rgba(245, 189, 0, 0.12);
}

.amount-orange {
    color: #FF7B00;
    background-color: rgba(255, 123, 0, 0.12);
}

.amount-pink {
    color: #D163AB;
    background-color: rgba(209, 99, 171, 0.12);
}

.amount-violet {
    color: #9748A3;
    background-color: rgba(151, 72, 163, 0.12);
}

.amount-decimal {
    opacity: 0.5;
}

.amount-analytics {
    font-size: 15px;
    line-height: 22px;
}

.amount-analytics-alt {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    background-color: transparent;
}

.amount-charges {
    color: #D163AB;
    background-color: rgba(209, 99, 171, 0.12);
    font-size: 15px;
    line-height: 22px;
}

/* TOOLS */

.tools {
    display: flex;
    row-gap: 10px;
    column-gap: 10px;
}

.tools-left {
    flex: auto;
    display: flex;
    column-gap: 10px;
    row-gap: 10px;
}

.tools-left-layout {
    display: flex;
    column-gap: 10px;
}

.tools-right {
    flex: none;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
}

.tools-fill {
    height: 10px;
}

.counter {
    display: flex;
    align-items: center;
    height: 100%;
    padding-bottom: 1px;
    box-sizing: border-box;
    color: #624DE3;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
}

.searcher {
    position: relative;
}

.searcher-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
}

.searcher-icon img {
    display: block;
    height: 100%;
}

.searcher-box {
    width: 216px;
    height: 42px;
    padding: 0;
    padding-left: 40px;
    padding-right: 16px;
    padding-bottom: 1px;
    margin: 0;
    box-sizing: border-box;
    border-style: none;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #624DE3;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    box-shadow: 0px 0px 0px 0px rgba(98, 77, 227, 0.5);
}

.searcher-box::placeholder {
    color: #B0A6F1;
    font-weight: 400;
    opacity: 1;
}

.searcher-box:focus {
    -webkit-animation: searcher-box-focus 0.5s ease-in-out forwards;
    animation: searcher-box-focus 0.5s ease-in-out forwards;
}

@keyframes searcher-box-focus {
    to {
        box-shadow: 0px 0px 70px 25px rgba(98, 77, 227, 0);
    }
}

.button-square {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background-color: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.18s;
}

.button-square svg {
    display: block;
    width: 100%;
    height: 100%;
}

.button-square:hover {
    background-color: rgba(98, 77, 227, 0.25);
}

.touch .button-square:hover {
    background-color: transparent;
}

.button-square:active,
.touch .button-square:active {
    background-color: rgba(98, 77, 227, 0.25);
}

.button-square-on {
    background-color: #624DE3;
}

.button-square-on svg path {
    fill: #FFFFFF;
}

.button-square-on:hover {
    background-color: #4837AB;
}

.touch .button-square-on:hover {
    background-color: #624DE3;
}

.button-square-on:active,
.touch .button-square-on:active {
    background-color: #4837AB;
}

.button-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    box-sizing: border-box;
    background-color: transparent;
    border-radius: 100px;
    border: solid 2px rgba(98, 77, 227, 0.25);
    cursor: pointer;
    transition: 0.18s;
}

.button-circle svg {
    display: block;
    width: 100%;
    height: 100%;
}

.button-circle:hover {
    background-color: rgba(98, 77, 227, 0.25);
    border-color: transparent;
}

.touch .button-circle:hover {
    background-color: transparent;
    border-color: rgba(98, 77, 227, 0.25);
}

.button-circle:active,
.touch .button-circle:active {
    background-color: rgba(98, 77, 227, 0.25);
    border-color: transparent;
}

.button-circle-on {
    background-color: #624DE3;
    border-color: transparent;
}

.button-circle-on svg path {
    fill: #FFFFFF;
}

.button-circle-on:hover {
    background-color: #4837AB;
}

.touch .button-circle-on:hover {
    background-color: #624DE3;
}

.button-circle-on:active,
.touch .button-circle-on:active {
    background-color: #4837AB;
}

.button-circle-on-blocked {
    cursor: default;
}

.button-circle-on-blocked:hover {
    background-color: #624DE3;
}

.touch .button-circle-on-blocked:hover {
    background-color: #624DE3;
}

.button-circle-on-blocked:active,
.touch .button-circle-on-blocked:active {
    background-color: #624DE3;
}

.tools-selection-display {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #624DE3;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    text-transform: capitalize;
}

/* FLOATING */

.floating {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 11;
}

.floating-show {
    display: block;
}

.floating-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 2, 8, 1);
    color: rgb(3, 2, 8);
    opacity: 0;
    transition: 0.25s;
}

.floating-show .floating-overlay {
    opacity: 0.35;
}

.floating-frame {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 24px;
    box-sizing: border-box;
}

.floating-window {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 420px;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 50px 0px rgba(50, 23, 122, 0.35);
    transform: scale(0.9);
    opacity: 0;
    transition: 0.25s;
}

.floating-show .floating-window {
    transform: scale(1);
    opacity: 1;
}

.floating-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 12;
}

.floating-blocks {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
}

.floating-content {
    flex: auto;
    overflow: auto;
}

.floating-content::-webkit-scrollbar {
    width: 11px;
    height: 11px;
    border-radius: 30px;
}

.floating-content::-webkit-scrollbar-thumb {
    background: #B0A6F1;
    border-radius: 30px;
    border: solid 3px #FFFFFF;
}

.floating-content::-webkit-scrollbar-thumb:hover {
    background: #624DE3;
}

.floating-content::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 30px;
}

.floating-group {
    padding-left: 20px;
    padding-right: 20px;
    border-top: solid 1px #EEEBFD;
    font-size: 13px;
    line-height: 16px;
    color: #978EC9;
    padding-top: 14px;
    padding-bottom: 16px;
}

.floating-group:first-of-type {
    border-top-color: transparent;
}

.floating-switchs {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 32px;
    box-sizing: border-box;
}

.floating-options {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 20px;
    padding-top: 0px;
    box-sizing: border-box;
}

.floating-options-space {
    padding-top: 20px;
}

.floating-full {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    box-sizing: border-box;
}

.floating-half {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
    column-gap: 8px;
    box-sizing: border-box;
}

.floating-third {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 8px;
    column-gap: 8px;
    box-sizing: border-box;
}

.floating-actions {
    flex: none;
    width: 100%;
    display: flex;
    column-gap: 8px;
    padding: 20px;
    box-sizing: border-box;
}

.floating-text-btn {
    display: flex;
    width: min-content;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #624DE3;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.18s;
}

.floating-text-btn:hover {
    opacity: 1;
}

.touch .floating-text-btn:hover {
    opacity: 0.5;
}

.floating-text-btn:active,
.touch .floating-text-btn:active {
    opacity: 1;
}

.floating-text-btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 8px;
}

.floating-text-btn-icon img {
    display: block;
    height: 16px;
}

.floating-close-frame {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    padding-top: 16px;
    padding-right: 16px;
    box-sizing: border-box;
}

.floating-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: #F7F4FF;
    cursor: pointer;
    transition: 0.18s;
}

.floating-close img {
    display: block;
    width: 12px;
}

.floating-close:hover {
    background-color: #EDEAFF;
}

.touch .floating-close:hover {
    background-color: #F7F4FF;
}

.floating-close:active,
.touch .floating-close:active {
    background-color: #EDEAFF;
}

/* BUTTONS */

.action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    background-color: #CCCCCC;
    color: #FFFFFF;
    height: 40px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.18s;
}

.action-button-purple {
    background-color: #624DE3;
}

.action-button-pink {
    background-color: #D163AB;
}

.action-button-purple:hover {
    background-color: #513EBF;
}

.touch .action-button-purple:hover {
    background-color: #624DE3;
}

.action-button-purple:active,
.touch .action-button-purple:active {
    background-color: #513EBF;
}

.action-button-pink:hover {
    background-color: #C83E98;
}

.touch .action-button-pink:hover {
    background-color: #D163AB;
}

.action-button-pink:active,
.touch .action-button-pink:active {
    background-color: #C83E98;
}

.command-button {
    display: inline-flex;
    column-gap: 6px;
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #624DE3;
    opacity: 0.6;
    transition: 0.18s;
}

.command-button-up {
    margin-top: -18px;
    margin-bottom: 17px;
}

.command-button div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.command-button img {
    display: block;
    width: 15px;
    margin-left: 6px;
}

.command-button:hover {
    opacity: 1;
}

.touch .command-button:hover {
    opacity: 0.6;
}

.command-button:active,
.touch .command-button:active {
    opacity: 1;
}

/* SWITCH */

.switch {
    display: inline-flex;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 24px;
}

.switch-nomargin {
    margin-top: 0;
    margin-bottom: 0;
}

.switch-compact {
    margin-bottom: 12px;
}

.switch-chip-bg {
    position: relative;
    width: 48px;
    height: 26px;
    border-radius: 100px;
    background-color: rgba(98, 77, 227, 0.45);
    padding: 4px;
    box-sizing: border-box;
    transition: 0.18s;
}

.switch:hover .switch-chip-bg {
    background-color: #4837AB;
    background-color: rgba(98, 77, 227, 0.6);
}

.touch .switch:hover .switch-chip-bg {
    background-color: rgba(98, 77, 227, 0.45);
}

.switch:active .switch-chip-bg,
.touch .switch:active .switch-chip-bg {
    background-color: rgba(98, 77, 227, 0.6);
}

.switch-chip-point {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 18px;
    height: 18px;
    border-radius: 100px;
    background-color: #FFFFFF;
    transition: 0.18s;
}

.switch-label {
    display: inline-flex;
    align-items: center;
    color: #41319C;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    padding-left: 10px;
}

.switch-on .switch-chip-point {
    left: 26px;
}

.switch-on .switch-chip-bg {
    justify-content: flex-end;
    background-color: #624DE3;
}

.switch-on:hover .switch-chip-bg {
    background-color: #4837AB;
}

.touch .switch-on:hover .switch-chip-bg {
    background-color: #624DE3;
}

.switch-on:active .switch-chip-bg,
.touch .switch-on:active .switch-chip-bg {
    background-color: #4837AB;
}

/* SWITCH MINI */

.switch-mini {
    display: inline-flex;
    cursor: pointer;
}

.switch-mini-chip {
    padding-top: 2px;
    padding-bottom: 2px;
    box-sizing: border-box;
}

.switch-mini-chip-bg {
    position: relative;
    width: 30px;
    height: 18px;
    padding: 3px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: rgba(98, 77, 227, 0.45);
    transition: 0.18s;
}

.switch-mini:hover .switch-mini-chip-bg {
    background-color: rgba(98, 77, 227, 0.6);
}

.touch .switch-mini:hover .switch-mini-chip-bg {
    background-color: rgba(98, 77, 227, 0.45);
}

.switch-mini:active .switch-mini-chip-bg,
.touch .switch-mini:active .switch-mini-chip-bg {
    background-color: rgba(98, 77, 227, 0.6);
}

.switch-mini-chip-point {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 100px;
    background-color: #FFFFFF;
    transition: 0.18s;
}

.switch-mini-label {
    display: inline-flex;
    align-items: center;
    padding-left: 7px;
    color: #4837AB;
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
}

.switch-mini-on .switch-mini-chip-point {
    left: 15px;
}

.switch-mini-on .switch-mini-chip-bg {
    justify-content: flex-end;
    background-color: #624DE3;
}

.switch-mini-on:hover .switch-mini-chip-bg {
    background-color: #4837AB;
}

.touch .switch-mini-on:hover .switch-mini-chip-bg {
    background-color: #624DE3;
}

.switch-mini-on:active .switch-mini-chip-bg,
.touch .switch-mini-on:active .switch-mini-chip-bg {
    background-color: #4837AB;
}

/* SWITCH BUTTON */

.switch-button {
    height: 40px;
    display: flex;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #624DE3;
    background-color: #FFFFFF;
    border: solid 2px rgba(98, 77, 227, 0.3);
    transition: 0.18s;
    cursor: pointer;
    float: left;
}

.switch-button:hover {
    border: solid 2px rgba(98, 77, 227, 0.65);
}

.touch .switch-button:hover {
    border: solid 2px rgba(98, 77, 227, 0.3);
}

.switch-button:active,
.touch .switch-button:active {
    border: solid 2px rgba(98, 77, 227, 0.65);
}

.switch-button-on {
    border: solid 2px rgba(98, 77, 227, 1);
}

.switch-button-on:hover {
    border: solid 2px rgba(98, 77, 227, 1);
}

.touch .switch-button-on:hover {
    border: solid 2px rgba(98, 77, 227, 1);
}

.switch-button-on:active,
.touch .switch-button-on:active {
    border: solid 2px rgba(98, 77, 227, 1);
}

/* DATEPICKER */

.datepicker {
    position: relative;
}

.datepicker-textbox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-bottom: 2px;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    border: solid 2px rgba(98, 77, 227, 0.3);
    background-color: #FFFFFF;
    color: #624DE3;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    transition: 0.18s;
}

.datepicker-textbox::placeholder {
    color: #624DE3;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    opacity: 1;
}

.datepicker-textbox:focus {
    border: solid 2px rgba(98, 77, 227, 1);
}

.datepicker-textbox-on {
    border: solid 2px rgba(98, 77, 227, 1) !important;
}

.datepicker-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 38px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datepicker-icon img {
    display: block;
    width: 18px;
}

/* PAGING */

.paging {
    display: flex;
    justify-content: center;
    margin-top: 32px;
}

.paging-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 100px;
    background-color: transparent;
    color: #9187CD;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    text-decoration: none;
    transition: 0.18s;
}

.paging-page:hover {
    color: #4837AB;
    background-color: #E1DDFF;
}

.touch .paging-page:hover {
    color: #9187CD;
    background-color: transparent;
}

.paging-page:active,
.touch .paging-page:active {
    color: #4837AB;
    background-color: #E1DDFF;
}

.paging-page-on {
    background-color: #FFFFFF;
    font-weight: 700;
    color: #4837AB;
}

.paging-page-on:hover {
    color: #4837AB;
    background-color: #FFFFFF;
}

.touch .paging-page-on:hover {
    color: #4837AB;
    background-color: #FFFFFF;
}

.paging-page-on:active,
.touch .paging-page-on:active {
    color: #4837AB;
    background-color: #FFFFFF;
}

.paging-page-prev img {
    display: block;
    transform: rotate(180deg);
    margin-right: 2px;
}

.paging-page-next img {
    display: block;
    margin-left: 2px;
}

.paging-page-off {
    opacity: 0.25;
}

/* MODAL */

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10;
}

.modal-show {
    display: block;
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 2, 8, 1);
    color: rgb(3, 2, 8);
    opacity: 0;
    transition: 0.25s;
}

.modal-show .modal-overlay {
    opacity: 0.7;
}

.modal-frame {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 24px;
    padding-bottom: 0px;
    box-sizing: border-box;
}

.modal-window {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 620px;
    height: 100%;
    border-top-left-radius: 31px;
    border-top-right-radius: 31px;
    box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    opacity: 0;
    transform: translateY(50%);
    transition: 0.25s;
}

.modal-show .modal-window {
    transform: translateY(0);
    opacity: 1;
}

.modal-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 12;
}

.modal-header {
    flex: none;
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    background-color: #DEDAFA;
}

.modal-content {
    flex: auto;
    height: 100%;
    box-sizing: border-box;
    background-color: #F0EEFF;
    overflow: auto;
}

.modal-content::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    border-radius: 30px;
}

.modal-content::-webkit-scrollbar-thumb {
    background: #BCB4F3;
    border-radius: 30px;
    border: solid 4px #F0EEFF;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background: #9D92ED;
}

.modal-content::-webkit-scrollbar-track {
    border-radius: 0px;
    background: #F0EEFF;
}

.modal-actions {
    flex: auto;
    display: flex;
    column-gap: 7px;
}

.modal-close {
    flex: none;
}

.modal-btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 46px;
    padding-bottom: 2px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    background-color: #D163AB;
    border-radius: 100px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
    cursor: pointer;
    transition: 0.18s;
}

.modal-btn-primary:hover {
    background-color: #DB68B4;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75);
}

.touch .modal-btn-primary:hover {
    background-color: #D163AB;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
}

.modal-btn-primary:active,
.touch .modal-btn-primary:active {
    background-color: #DB68B4;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75);
}

.avatar-changer-buttons .modal-btn-primary,
.uploader-picture-buttons .modal-btn-primary {
    min-height: 40px;
}

.modal-btn-secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 46px;
    padding-bottom: 2px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    border: solid 1px transparent;
    background-color: #FFFFFF;
    border-radius: 100px;
    color: #D163AB;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    transition: 0.18s;
}

.modal-btn-secondary:hover {
    background-color: #F1D0E6;
}

.touch .modal-btn-secondary:hover {
    background-color: #FFFFFF;
}

.modal-btn-secondary:active,
.touch .modal-btn-secondary:active {
    background-color: #F1D0E6;
}

.avatar-changer-buttons .modal-btn-secondary,
.uploader-picture-buttons .modal-btn-secondary {
    min-height: 40px;
}

.modal-close-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-radius: 100px;
    background-color: #FFFFFF;
    cursor: pointer;
    z-index: 13;
    transition: 0.18s;
}

.modal-close-btn img {
    display: block;
    width: 14px;
}

.modal-close-btn:hover {
    box-shadow: 0px 0px 0px 5px rgba(209, 99, 171, 0.25);
}

.touch .modal-close-btn:hover {
    box-shadow: none;
}

.modal-close-btn:active,
.touch .modal-close-btn:active {
    box-shadow: 0px 0px 0px 5px rgba(209, 99, 171, 0.25);
}

.modal-back-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-radius: 100px;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: 0.18s;
}

.modal-back-btn img {
    display: block;
    width: 14px;
}

.modal-back-btn:hover {
    box-shadow: 0px 0px 0px 5px rgba(98, 77, 227, 0.25);
}

.touch .modal-back-btn:hover {
    box-shadow: none;
}

.modal-back-btn:active,
.touch .modal-back-btn:active {
    box-shadow: 0px 0px 0px 5px rgba(98, 77, 227, 0.25);
}

.modal-form {
    padding: 24px;
    padding-bottom: 64px;
}

.modal-form-centered {
    display: flex;
    justify-content: center;
}

.modal-form-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.modal-title {
    padding-top: 8px;
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: solid 2px #D8D2F8;
    color: #624DE3;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
}

.modal-info {
    padding: 24px;
    box-sizing: border-box;
}

.modal-form-divisor {
    width: 100%;
    height: 16px;
}

.modal-commands {
    padding-top: 8px;
}

.modal-explanation {
    padding-bottom: 16px;
    color: #624DE3;
    font-size: 15px;
    line-height: 21px;
}

.modal-explanation-alt {
    padding: 24px;
    padding-top: 12px;
}

.modal-explanation-alt-2 {
    padding-bottom: 24px;
}

.modal-explanation-intro {
    padding-bottom: 24px;
}

.modal-explanation-center {
    text-align: center;
}

.modal-explanation strong {
    font-weight: 600;
}

.modal-explanation p {
    padding: 0;
    padding-bottom: 10px;
    margin: 0;
}

.modal-explanation p:last-of-type {
    padding-bottom: 0px;
}

.modal-explanation a,
.modal-explanation a:link,
.modal-explanation a:visited {
    color: #624DE3;
    text-decoration: underline;
    font-weight: 600;
    transition: 0.18s;
}

.modal-explanation a:hover {
    color: #D163AB;
}

.touch .modal-explanation a:hover {
    color: #624DE3;
}

.modal-explanation a:active,
.touch .modal-explanation a:active {
    color: #D163AB;
}

.modal-text {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    color: #D163AB;
    font-size: 16px;
    line-height: 24px;
}

.modal-text p {
    padding: 0;
    padding-bottom: 10px;
    margin: 0;
}

.modal-inline-actions {
    display: flex;
    height: 60px;
    padding: 8px;
    padding-left: 16px;
    margin-top: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.modal-inline-actions-left {
    display: flex;
    align-items: center;
    width: 50%;
}

.modal-inline-actions-right {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 50%;
}

/* DIALOG */

.dialog {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
}

.dialog-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 2, 8, 1);
    opacity: 0;
    transition: 0.25s;
}

.dialog-show .dialog-overlay {
    opacity: 0.7;
}

.dialog-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    box-sizing: border-box;
}

.dialog-window {
    position: relative;
    width: auto;
    max-width: 400px;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.35);
    transform: scale(0.9);
    opacity: 0;
    transition: 0.25s;
}

.dialog-show .dialog-window {
    transform: scale(1);
    opacity: 1;
}

.dialog-message {
    padding-top: 34px;
    padding-bottom: 24px;
    padding-left: 44px;
    padding-right: 44px;
    box-sizing: border-box;
    color: #41319C;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 500;
}

.dialog-message strong {
    font-weight: 700;
}

.dialog-actions {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    padding: 32px;
    padding-top: 0px;
    box-sizing: border-box;
}

.dialog-action-1 {
    display: inline-flex;
    justify-content: center;
    height: 46px;
    padding-top: 14px;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: rgba(98, 77, 227, 0.25);
    color: #624DE3;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: 0.18s;
}

.dialog-action-1:hover {
    background-color: rgba(98, 77, 227, 1);
    color: #FFFFFF;
}

.touch .dialog-action-1:hover {
    background-color: rgba(98, 77, 227, 0.25);
    color: #624DE3;
}

.dialog-action-1:active,
.touch .dialog-action-1:active {
    background-color: rgba(98, 77, 227, 1);
    color: #FFFFFF;
}

.dialog-action-2 {
    display: inline-flex;
    justify-content: center;
    height: 46px;
    padding-top: 12px;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: transparent;
    border: solid 2px rgba(98, 77, 227, 0.25);
    color: #624DE3;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: 0.18s;
}

.dialog-action-2:hover {
    border-color: rgba(98, 77, 227, 1);
}

.touch .dialog-action-2:hover {
    border-color: rgba(98, 77, 227, 0.25);
}

.dialog-action-2:active,
.touch .dialog-action-2:active {
    border-color: rgba(98, 77, 227, 1);
}

/* TOAST */

.toast {
    display: none;
    position: fixed;
    top: 0;
    left: 50%;
    padding: 16px;
    max-width: 100%;
    box-sizing: border-box;
    transform: translateX(-50%);
    z-index: 12;
}

.toast-box {
    display: inline-block;
    background: #4837AB;
    background: -moz-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: -webkit-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    padding: 14px;
    padding-bottom: 16px;
    padding-left: 27px;
    padding-right: 27px;
    box-sizing: border-box;
    border-radius: 100px;
    color: #75E8F0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    transform: translateY(-100%);
    opacity: 0;
    box-shadow: 0px 0px 40px 2px rgba(98, 77, 227, 0.5);
    transition: 0.4s;
}

.toast-alt .toast-box {
    color: #4837AB;
    background: #75E8F0;
    background: -moz-linear-gradient(45deg, #6BDAE2 0%, #75E8F0 100%);
    background: -webkit-linear-gradient(45deg, #6BDAE2 0%, #75E8F0 100%);
    background: linear-gradient(45deg, #6BDAE2 0%, #75E8F0 100%);
}

.toast-box strong {
    font-weight: 700;
}

.toast-show .toast-box {
    transform: translateY(0);
    opacity: 1;
}

/* FORM */

.form-block {
    position: relative;
    padding-top: 0px;
    margin-bottom: 16px;
}

.form-block-nomargin {
    margin-bottom: 0;
}

/* TEXTBOX */

.textbox-label,
.textarea-label {
    position: absolute;
    top: 21px;
    left: 21px;
    background-color: #FFFFFF;
    color: #675AB0;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    cursor: text;
    transition: 0.18s;
}

.textbox-label-on,
.textarea-label-on {
    top: 11px;
    left: 13px;
    font-size: 13px;
    line-height: 13px;
}

.textbox {
    display: block;
    width: 100%;
    height: 57px;
    padding-top: 16px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 0px;
    margin: 0;
    box-sizing: border-box;
    border: solid 1px #FFFFFF;
    border-radius: 8px;
    background-color: #FFFFFF;
    color: #41319C;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    transition: 0.18s;
}

.textbox:focus {
    border-color: #624DE3;
    box-shadow: 0 0 0 1px #624DE3;
}

.textbox:disabled {
    color: rgba(65, 49, 156, 0.35);
    cursor: not-allowed;
}

.textbox-with-icon {
    padding-right: 67px;
}

.textbox-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 57px;
}

.textbox-icon img {
    display: block;
    width: 100%;
    height: 100%;
}

/* TEXTAREA */

.textarea {
    display: block;
    width: 100%;
    height: 240px;
    padding-top: 26px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 0px;
    margin: 0;
    box-sizing: border-box;
    border: solid 1px #FFFFFF;
    border-radius: 8px;
    background-color: #FFFFFF;
    color: #41319C;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    resize: none;
    transition: 0.18s;
}

.textarea:focus {
    border-color: #624DE3;
    box-shadow: 0 0 0 1px #624DE3;
}

.textarea::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    border-radius: 30px;
}

.textarea::-webkit-scrollbar-thumb {
    background: #DBDBDB;
    border-radius: 30px;
    border: solid 4px #FFFFFF;
}

.textarea::-webkit-scrollbar-thumb:hover {
    background: #CCCCCC;
}

.textarea::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px;
}

/* EDITOR */

.editor {
    width: 100%;
    padding-top: 13px;
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 13px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 8px;
    color: #41319C;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
}

.ce-paragraph[data-placeholder]:empty::before {
    padding-left: 8px !important;
    color: #675AB0 !important;
    font-size: 15px !important;
    line-height: 15px !important;
    font-weight: 500 !important;
}

.ce-toolbar__plus,
.ce-toolbar__settings-btn,
.ce-inline-tool,
.ce-inline-toolbar__dropdown {
    color: #D163AB !important;
}

.ce-inline-tool,
.ce-inline-toolbar__dropdown {
    border-radius: 4px !important;
    padding-left: 8px;
    padding-right: 8px;
    border-style: none !important;
}

.ce-toolbar__plus:hover,
.ce-toolbar__settings-btn:hover,
.ce-inline-tool:hover,
.ce-inline-toolbar__dropdown:hover {
    background-color: #F6E0EE !important;
    transition: 0.18s;
}

.cdx-search-field {
    display: none !important;
}

.ce-popover__items {
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.ce-popover__item:hover {
    background-color: #F0EEFF !important;
    transition: 0.18s;
}

.ce-popover__item--confirmation:hover {
    background: #D92222 !important;
}

.ce-popover__item-icon {
    -webkit-box-shadow: 0 0 0 1px rgba(240, 238, 255, 1) !important;
    box-shadow: 0 0 0 1px rgba(240, 238, 255, 1) !important;
}

.ce-popover {
    right: 0 !important;
    border: 0px solid #D8D2F8 !important;
    -webkit-box-shadow: 0 0 16px 0 rgba(222, 218, 250, 1) !important;
    box-shadow: 0 0 16px 0 rgba(222, 218, 250, 1) !important;
}

.ce-inline-toolbar {
    padding: 8px !important;
    margin: 0 !important;
    border: 0px solid #D8D2F8 !important;
    -webkit-box-shadow: 0 0 16px 0 rgba(246, 224, 238, 1) !important;
    box-shadow: 0 0 16px 0 rgba(246, 224, 238, 1) !important;
}

.ce-inline-toolbar__toggler-and-button-wrapper {
    padding: 0px !important;
    padding-left: 6px !important;
}

.ct {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ct:before,
.ct:after {
    background-color: #D163AB !important;
}

.ct__content {
    color: #FFFFFF !important;
}

.cdx-button {
    display: inline-block;
    background-color: #D8D2F8 !important;
    border: none !important;
    border-radius: 100px !important;
    padding: 9px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin-bottom: 24px !important;
    box-sizing: border-box;
    color: #624DE3 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 500;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    transition: 0.18s;
}

.cdx-button:hover {
    color: #D163AB !important;
    background-color: #F3D8EA !important;
}

.cdx-input {
    border: 1px solid rgba(240, 238, 255, 1) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 3px;
    padding: 10px 12px;
    color: #41319C !important;
    font-size: 14px !important;
    line-height: 22px !important;
}

.image-tool__caption[contentEditable="true"][data-placeholder]::before {
    color: #41319C !important;
    opacity: 0.5 !important;
}

.embed-tool__caption {
    color: #41319C !important;
}

.embed-tool__caption::placeholder {
    color: #41319C !important;
    opacity: 0.5 !important;
}

/* UPLOADER */

.uploader {
    display: block;
}

.uploader-input {
    display: none;
}

.uploader-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 57px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: transparent;
    border: solid 2px #CCC6F8;
    color: #624DE3;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.18s;
}

.uploader-button:hover {
    background-color: #CCC6F8;
    border-color: transparent;
}

.touch .uploader-button:hover {
    background-color: transparent;
    border-color: #CCC6F8;
}

.uploader-button:active,
.touch .uploader-button:active {
    background-color: #CCC6F8;
    border-color: transparent;
}

.uploader-button-layout {
    display: flex;
    column-gap: 10px;
    padding-bottom: 1px;
}

.uploader-icon {
    padding-top: 2px;
}

.uploader-icon img {
    display: block;
    height: 16px;
}

.uploader-note {
    padding-top: 6px;
    color: #624DE3;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    opacity: 0.5;
}

.uploader-name {
    padding-top: 14px;
    padding-bottom: 5px;
    color: #D163AB;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
}

.uploader-submit {
    width: 100%;
    max-width: 270px;
    height: 46px;
    padding-top: 16px;
    margin: 0 auto;
}

/* UPLOADER PICTURE */

.uploader-picture {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
}

.uploader-picture>div {
    width: 100%;
}

.uploader-picture-display {
    width: 100%;
    margin-bottom: 16px;
    background-color: #E4E0FB;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.uploader-picture-display-article {
    padding-bottom: 56.25%;
}

.uploader-picture-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 50px;
    box-sizing: border-box;
    background-color: rgba(240, 238, 255, 0.75);
    color: #624DE3;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
}

.uploader-picture-loading>div {
    animation: uploader-picture-loading-anima 0.5s infinite alternate;
}

@keyframes uploader-picture-loading-anima {
    from {
        opacity: 0;
    }
}

.uploader-picture-btn {
    display: inline-block;
    background-color: #D8D2F8;
    border-radius: 100px;
    padding: 9px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 24px;
    box-sizing: border-box;
    color: #624DE3;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.18s;
}

.uploader-picture-btn:hover {
    color: #D163AB;
    background-color: #F3D8EA;
}

.touch .uploader-picture-btn:hover {
    color: #624DE3;
    background-color: #D8D2F8;
}

.uploader-picture-btn:active,
.touch .uploader-picture-btn:active {
    color: #D163AB;
    background-color: #F3D8EA;
}

.uploader-picture-crop {
    width: 100% !important;
    overflow: hidden;
}

.uploader-picture-slider {
    display: block;
    width: 100%;
    height: 10px;
    margin: 0 auto;
    margin-top: 14px;
    background: #D8D2F8;
    border-radius: 5px;
    outline: none;
    opacity: 1;
    transition: opacity .2s;
    appearance: none;
    -webkit-appearance: none;
    z-index: 2;
}

.uploader-picture-slider::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #624DE3;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
}

.uploader-picture-slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #624DE3;
    cursor: pointer;
}

.uploader-picture .ReactCrop__crop-selection {
    -webkit-box-shadow: 0px 0px 1px 1px transparent, 0 0 0 9999em rgba(65, 49, 156, 0.5) !important;
    box-shadow: 0px 0px 1px 1px transparent, 0 0 0 9999em rgba(65, 49, 156, 0.5) !important;
}

.uploader-picture .ReactCrop__crop-selection {
    border: 1px solid transparent !important;
}

.uploader-picture-buttons {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    padding-top: 18px;
    padding-bottom: 32px;
}

/* AVATAR CHANGER */

.avatar-changer {
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
    text-align: center;
}

.avatar-changer-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 16px;
    box-sizing: border-box;
    background-color: rgba(240, 238, 255, 0.75);
    color: #624DE3;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
}

.avatar-changer-loading>div {
    animation: avatar-changer-loading-anima 0.5s infinite alternate;
}

@keyframes avatar-changer-loading-anima {
    from {
        opacity: 0;
    }
}

.avatar-changer-btn {
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 4px;
    color: #624DE3;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.18s;
}

.avatar-changer-btn:hover {
    color: #D163AB;
}

.touch .avatar-changer-btn:hover {
    color: #624DE3;
}

.avatar-changer-btn:active,
.touch .avatar-changer-btn:active {
    color: #D163AB;
}

.avatar-changer-crop {
    width: 100% !important;
    max-width: 200px !important;
    border-radius: 8px;
    overflow: hidden;
}

.avatar-changer-slider {
    display: block;
    width: 100%;
    height: 10px;
    margin: 0 auto;
    margin-top: 14px;
    background: #D8D2F8;
    border-radius: 5px;
    outline: none;
    opacity: 1;
    transition: opacity .2s;
    appearance: none;
    -webkit-appearance: none;
    z-index: 2;
}

.avatar-changer-slider::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #624DE3;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
}

.avatar-changer-slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #624DE3;
    cursor: pointer;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection {
    -webkit-box-shadow: 0px 0px 1px 1px transparent, 0 0 0 9999em rgba(65, 49, 156, 0.5) !important;
    box-shadow: 0px 0px 1px 1px transparent, 0 0 0 9999em rgba(65, 49, 156, 0.5) !important;
}

.ReactCrop__crop-selection {
    border: 1px solid transparent !important;
}

.avatar-changer-buttons {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 18px;
    margin-bottom: 8px;
}

/* PLACES SELECTOR */

.selector-places {
    position: relative;
}

.selector-places-options {
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 17px;
    overflow: hidden;
    box-sizing: border-box;
    border: solid 2px rgba(209, 99, 171, 0.25);
    z-index: 11;
    animation-name: selector-places-options-anima;
    animation-duration: 0.18s;
}

@keyframes selector-places-options-anima {
    0% {
        transform: scale(0.95);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.selector-places-option {
    padding: 7px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    font-weight: 400;
    color: #D163AB;
    font-size: 14px;
    line-height: 19px;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: 0.18s;
}

.selector-places-option strong {
    font-weight: 600;
}

.selector-places-option:hover {
    background-color: rgba(209, 99, 171, 0.1);
}

.touch .selector-places-option:hover {
    background-color: #FFFFFF;
}

.selector-places-option:active,
.touch .selector-places-option:active {
    background-color: rgba(209, 99, 171, 0.1);
}

/* BOXES */

.boxes {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-top: 24px;
    padding-bottom: 100px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
}

.box {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 10px;
    color: #32177A;
    font-size: 15px;
    line-height: 20px;
    max-width: 754px;
    padding: 30px;
    box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
}

.box-info {
    flex: auto;
}

.box-title {
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    padding-bottom: 16px;
    padding-top: 6px;
}

.box-description {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 13px;
}

.box-description-featured {
    color: #9748A3;
}

.box-description-space {
    margin-bottom: 4px;
}

.box-button {
    display: inline-block;
    color: #D163AB;
    font-size: 17px;
    line-height: 17px;
    white-space: nowrap;
    font-weight: 600;
    cursor: pointer;
    background-color: transparent;
    padding: 11px;
    padding-bottom: 12px;
    padding-left: 17px;
    padding-right: 16px;
    border-radius: 50px;
    text-decoration: none;
    margin-left: -17px;
    transition: 0.18s;
}

.box-button img {
    display: inline-block;
    width: auto;
    height: 13px;
    margin: 0 0 0;
    margin-top: -1px;
    margin-left: 14px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.box-button:hover {
    background-color: rgba(209, 99, 171, 0.25);
}

.touch .box-button:hover {
    background-color: transparent;
}

.box-button:active,
.touch .box-button:active {
    background-color: rgba(209, 99, 171, 0.25);
}

.box-button:hover img {
    margin-left: 20px;
}

.touch .box-button:hover img {
    margin-left: 14px;
}

.box-button:active img,
.touch .box-button:active img {
    margin-left: 20px;
}

.box-picture {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
}

.box-picture img {
    display: block;
    width: 200px;
}

.box-modules {
    display: block;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    max-width: 320px;
}

/* WEBPOS */

.webpos {
    width: 100%;
    max-width: 740px;
    padding-top: 40px;
    padding-bottom: 70px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    box-sizing: border-box;
}

.webpos-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 100px;
    background: #4837AB;
    background: -moz-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: -webkit-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    box-shadow: 3px 3px 20px 0px rgba(98, 77, 227, 0.5);
}

.webpos-icon img {
    display: block;
    width: 38px;
}

.webpos-block {
    padding-top: 24px;
}

.webpos-label {
    padding-bottom: 16px;
    color: #32177A;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
}

.webpos-concept {
    display: flex;
    column-gap: 12px;
    row-gap: 8px;
}

.webpos-concept-detail {
    width: 70%;
}

.webpos-concept-reference {
    width: 30%;
}

.webpos-detail {
    display: block;
    width: 100%;
    height: 58px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #624DE3;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0px 0px 0px 0px rgba(98, 77, 227, 0.5);
    transition: 0.18s;
}

.webpos-detail-alt {
    height: 50px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;
}

.webpos-reference {
    font-weight: 400;
}

.webpos-detail::placeholder {
    color: #B0A6F1;
    font-weight: 400;
    opacity: 1;
}

.webpos-detail:focus {
    border-color: #624DE3;
    animation: webpos-detail-focus 0.5s ease-in-out forwards;
}

@keyframes webpos-detail-focus {
    to {
        box-shadow: 0px 0px 70px 25px rgba(98, 77, 227, 0);
    }
}

.webpos-amount {
    display: block;
    width: 100%;
    height: 140px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #624DE3;
    font-size: 72px;
    line-height: 72px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0px 0px 0px 0px rgba(98, 77, 227, 0.5);
    transition: 0.18s;
}

.webpos-amount-alt {
    height: 50px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
}

.webpos-amount::placeholder {
    color: #B0A6F1;
    font-weight: 400;
    opacity: 1;
}

.webpos-amount:focus {
    border-color: #624DE3;
    animation: webpos-amount-focus 0.5s ease-in-out forwards;
}

@keyframes webpos-amount-focus {
    to {
        box-shadow: 0px 0px 70px 25px rgba(98, 77, 227, 0);
    }
}

.webpos-items {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.webpos-item {
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
    width: 100%;
}

.webpos-item-detail {
    width: 50%;
}

.webpos-item-reference {
    width: 30%;
}

.webpos-item-amount {
    width: 20%;
}

.webpos-item-eraser {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 2px #CBC4F8;
    background-color: transparent;
    cursor: pointer;
    transition: 0.18s;
}

.webpos-item-eraser img {
    display: block;
    width: 20px;
}

.webpos-item-eraser:hover {
    border-color: #624DE3;
}

.touch .webpos-item-eraser:hover {
    border-color: #CBC4F8;
}

.webpos-item-eraser:active,
.touch .webpos-item-eraser:active {
    border-color: #624DE3;
}

.webpos-item-eraser-off {
    opacity: 0.4 !important;
}

.webpos-actions {
    padding-top: 24px;
    text-align: center;
}

.webpos-add {
    display: inline-flex;
    color: #624DE3;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    opacity: 0.5;
    cursor: pointer;
    transition: 0.18s;
}

.webpos-add img {
    display: block;
    width: 20px;
    margin-top: 2px;
    margin-right: 8px;
}

.webpos-add:hover {
    opacity: 1;
}

.touch .webpos-add:hover {
    opacity: 0.5;
}

.webpos-add:active,
.touch .webpos-add:active {
    opacity: 1;
}

.webpos-continue {
    max-width: 300px;
    padding-top: 20px;
    margin: 0 auto;
}

.webpos-total {
    display: flex;
    padding: 20px;
    margin-top: 16px;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 2px #B0A6F1;
    color: #624DE3;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
}

.webpos-total-caption {
    flex: auto;
}

.webpos-total-value {
    flex: none;
    font-weight: 700;
}

.webpos-cancel {
    width: 100%;
    padding-top: 10px;
    text-align: center;
}

.webpos-cancel div {
    display: inline-block;
    color: #B6AEE6;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.18s;
}

.webpos-cancel div:hover {
    color: #624DE3;
}

.touch .webpos-cancel div:hover {
    color: #B6AEE6;
}

.webpos-cancel div:active,
.touch .webpos-cancel div:active {
    color: #624DE3;
}

/* CARD */

.card {
    padding: 24px;
    padding-bottom: 36px;
    margin-bottom: 24px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 54px 0px rgba(50, 23, 122, 0.05);
}

.card-single {
    padding-top: 12px;
    padding-bottom: 12px;
}

.card-header {
    display: flex;
    padding-top: 4px;
}

.card-icon {
    flex: none;
    padding-right: 14px;
}

.card-icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    padding: 1px;
    box-sizing: border-box;
    border-radius: 200px;
    background-color: rgba(98, 77, 227, 0.25);
}

.card-icon-circle img {
    display: block;
    width: 100%;
    height: 100%;
}

.card-title {
    flex: auto;
    display: flex;
    align-items: center;
    color: #32177A;
    font-size: 19px;
    line-height: 25px;
    font-weight: 600;
}

.card-title-single {
    padding-bottom: 10px;
}

.card-actions {
    flex: none;
    display: flex;
    align-items: center;
}

.card-data {
    padding-left: 58px;
}

.card-data-single {
    padding-left: 0px;
}

.card-row {
    display: flex;
    column-gap: 16px;
    padding-top: 13px;
    padding-bottom: 13px;
    border-bottom: solid 1px #EEEBFD;
    color: #32177A;
    font-size: 15px;
    line-height: 23px;
}

.card-row-draggable {
    left: auto !important;
    border-bottom: solid 1px #EEEBFD;
}

.card-row-draggable:last-of-type {
    border-bottom-style: none;
}

.card-row-featured {
    color: #D163AB;
}

.card-row-medium {
    font-weight: 500;
}

.card-row-semibold {
    font-weight: 600;
}

.card-row-green {
    color: #2DCA73;
}

.card-row-red {
    color: #EB3941;
}

.card-row-off {
    opacity: 0.5;
}

.card-row:last-of-type {
    border-bottom-style: none;
}

.card-row-left {
    display: flex;
    flex: auto;
    font-weight: 500;
}

.card-row-left-content {
    width: 100%;
}

.card-row-right {
    flex: none;
    text-overflow: ellipsis;
    overflow: hidden;
}

.card-action {
    display: flex;
    cursor: pointer;
}

.card-action-icon {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 100px;
    background-color: rgba(209, 99, 171, 0);
    transition: 0.18s;
}

.card-action-icon img {
    display: block;
    width: 16px;
}

.card-action-label {
    flex: auto;
    display: flex;
    align-items: center;
    padding-left: 2px;
    color: #D163AB;
    font-size: 15px;
    line-height: 23px;
    font-weight: 600;
}

.card-detail {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.card-symbol {
    padding-right: 10px;
}

.card-symbol .symbol:nth-child(2) {
    margin-top: 6px;
}

.card-action:hover .card-action-icon {
    background-color: rgba(209, 99, 171, 0.25);
}

.touch .card-action:hover .card-action-icon {
    background-color: rgba(209, 99, 171, 0);
}

.card-action:active .card-action-icon,
.touch .card-action:active .card-action-icon {
    background-color: rgba(209, 99, 171, 0.25);
}

.card-tag {
    display: inline-block;
    padding-top: 6px;
    padding-bottom: 7px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 4px;
    border-radius: 100px;
    background-color: rgba(209, 99, 171, 0.2);
    color: #D163AB;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
}

.card-buttons {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.card-button {
    display: inline-block;
    padding: 6px 11px;
    border-radius: 5px;
    border: 2px solid rgba(209, 99, 171, 0.25);
    background-color: transparent;
    color: #D163AB;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: .18s;
}

.card-button:hover {
    background-color: rgba(209, 99, 171, 0.25);
}

.touch .card-button:hover {
    background-color: transparent;
}

.card-button:active,
.touch .card-button:active {
    background-color: rgba(209, 99, 171, 0.25);
}

.card-button-secondary {
    border: 2px solid #E6E6E6;
    color: #A5A5A5;
}

.card-button-secondary:hover {
    background-color: #E6E6E6;
}

.touch .card-button-secondary:hover {
    background-color: transparent;
}

.card-button-secondary:active,
.touch .card-button-secondary:active {
    background-color: #E6E6E6;
}

.card-button-violet {
    border: 2px solid rgba(141, 131, 196, 0.25);
    color: #8D83C4;
}

.card-button-violet:hover {
    background-color: rgba(141, 131, 196, 0.25);
}

.touch .card-button-violet:hover {
    background-color: transparent;
}

.card-button-violet:active,
.touch .card-button-violet:active {
    background-color: rgba(141, 131, 196, 0.25);
}

.card-link {
    display: inline-block;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 4px;
    margin-bottom: 5px;
    border-radius: 7px;
    background-color: rgba(209, 99, 171, 0.2);
    color: #D163AB;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.18s;
}

.card-link:hover {
    background-color: rgba(209, 99, 171, 0.25);
}

.touch .card-link:hover {
    background-color: transparent;
}

.card-link:active,
.touch .card-link:active {
    background-color: rgba(209, 99, 171, 0.25);
}

.card-info {
    padding: 16px;
    padding-top: 12px;
    padding-bottom: 14px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: solid 2px rgba(65, 49, 156, 0.5);
    border-radius: 8px;
    color: #41319C;
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
}

.card-info strong {
    font-weight: 600;
}

.card-code {
    padding-top: 14px;
    padding-bottom: 15px;
}

.card-textbox-mini-copy {
    display: flex;
    column-gap: 4px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 7px;
}

.card-textbox-mini {
    display: block;
    width: 200px;
    padding-top: 6px;
    padding-bottom: 7px;
    padding-left: 9px;
    padding-right: 9px;
    box-sizing: border-box;
    border-radius: 6px;
    border: solid 1px #E2E2E2;
    color: #9748A3;
    font-size: 14px;
    line-height: 14px;
    transition: 0.18s;
}

.card-textbox-mini::selection {
    background: #FFA2DF;
}

.card-textbox-mini::-moz-selection {
    background: #FFA2DF;
}

.card-textbox-mini:focus {
    border-color: #9748A3;
}

.card-textbox-mini-full {
    width: 100%;
}

.card-row-drag {
    padding-top: 4px;
    padding-left: 10px;
    box-sizing: border-box;
    height: 100%;
}

.card-row-drag img {
    display: block;
    height: 14px;
}

/* ADVICE */

.advice {
    padding: 16px;
    padding-top: 14px;
    margin-bottom: 24px;
    box-sizing: border-box;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
}

.advice strong {
    font-weight: 600;
}

.advice-orange {
    background-color: #FF7B00;
    box-shadow: 0px 0px 24px 0px rgba(255, 123, 0, 0.25);
}

.advice-yellow {
    background-color: #F5BD00;
    box-shadow: 0px 0px 24px 0px rgba(245, 189, 0, 0.25);
}

.advice-green {
    background-color: #2DCA73;
    box-shadow: 0px 0px 24px 0px rgba(45, 202, 115, 0.25);
}

.advice-red {
    background-color: #EB3941;
    box-shadow: 0px 0px 24px 0px rgba(235, 57, 65, 0.25);
}

.advice-button {
    display: inline-block;
    padding: 6px 11px;
    margin-top: 8px;
    border-radius: 5px;
    border: 2px solid rgba(255, 255, 255, 0.25);
    background-color: transparent;
    color: #FFFFFF;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: .18s;
}

.advice-button:hover {
    background-color: rgba(255, 255, 255, 0.25);
    border-color: transparent;
}

.touch .advice-button:hover {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.25);
}

.advice-button:active,
.touch .advice-button:active {
    background-color: rgba(255, 255, 255, 0.25);
    border-color: transparent;
}

/* TICKETS */

.ticket-info {
    padding-top: 24px;
    color: #41319C;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
}

.ticket-avatar>div {
    margin: 0 auto;
}

.ticket-shop {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 600;
}

.ticket-strong {
    font-weight: 600;
}

.ticket-immediate {
    margin-bottom: 24px;
    box-sizing: border-box;
    color: #624DE3;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.ticket-immediate a,
.ticket-immediate a:link,
.ticket-immediate a:visited {
    color: #624DE3;
    font-weight: 600;
    text-decoration: none;
}

/* MESSAGES */

.message {
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #675AB0;
    font-size: 15px;
    line-height: 21px;
    box-shadow: 0px 0px 54px 0px rgba(50, 23, 122, 0.05);
}

.message-connector {
    width: 2px;
    height: 24px;
    margin: 0 auto;
    background-color: #D8D2F8;
}

.message-main {
    border: solid 2px #675AB0;
}

.message-content {
    padding: 24px;
    padding-top: 18px;
}

.message-content p {
    padding: 0;
    padding-bottom: 10px;
    margin: 0;
}

.message-content p:last-of-type {
    padding-bottom: 0px;
}

.message-author {
    border-bottom: solid 1px #E9E6FF;
}

.message-author-layout {
    display: flex;
    column-gap: 12px;
    padding: 12px;
}

.message-author-info {
    display: flex;
    align-items: center;
    color: #9748A3;
    font-size: 14px;
    line-height: 20px;
}

.message-author-info strong {
    font-size: 15px;
    font-weight: 600;
}

/* SHARE */

.share-link {
    width: 100%;
}

.share-link-box {
    flex: auto;
}

.share-link-box input {
    display: block;
    width: 100%;
    height: 57px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 12px;
    padding-right: 12px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 8px;
    border: solid 2px #DB68B4;
    background-color: #FFFFFF;
    color: #41319C;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    transition: 0.18s;
}

.share-link-box input::selection {
    background: #FFA2DF;
}

.share-link-box input::-moz-selection {
    background: #FFA2DF;
}

.share-link-buttons {
    display: flex;
    justify-content: center;
    column-gap: 16px;
    padding-top: 16px;
}

.share-link-copy {
    flex: none;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    border-radius: 200px;
    border: solid 2px transparent;
    background-color: #624DE3;
    cursor: pointer;
    transition: 0.18s;
}

.share-link-copy-small {
    width: 32px;
    height: 32px;
}

.share-link-copy img {
    display: block;
    width: 100%;
    height: 100%;
}

.share-link-copy:hover {
    background-color: #DB68B4;
}

.touch .share-link-copy:hover {
    background-color: #624DE3;
}

.share-link-copy:active,
.touch .share-link-copy:active {
    background-color: #DB68B4;
}

.share-link-qr {
    flex: none;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    border-radius: 200px;
    border: solid 2px #624DE3;
    cursor: pointer;
    transition: 0.18s;
}

.share-link-qr:hover {
    border: solid 2px #DB68B4;
}

.touch .share-link-qr:hover {
    border: solid 2px #624DE3;
}

.share-link-qr:active,
.touch .share-link-qr:active {
    border: solid 2px #DB68B4;
}

.share-link-qr svg {
    display: block;
    width: 100%;
    height: 100%;
}

.share-link-qr svg path {
    transition: 0.18s;
}

.share-link-qr:hover svg path {
    fill: #DB68B4;
}

.touch .share-link-qr:hover svg path {
    fill: #624DE3;
}

.share-link-qr:active svg path,
.touch .share-link-qr:active svg path {
    fill: #DB68B4;
}

.share-buttons {
    display: flex;
    justify-content: center;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
    padding-top: 32px;
    margin: 0 auto;
}

.share-button {
    width: 60px;
    height: 60px;
    border-radius: 300px;
    background-color: #D8D2F8;
    transition: 0.18s;
}

.share-button img {
    display: block;
    width: 100%;
    height: 100%;
}

.share-button:hover {
    transform: scale(1.2);
}

.touch .share-button:hover {
    transform: scale(1);
}

.share-button:active,
.touch .share-button:active {
    transform: scale(1.2);
}

/* PAYMENT */

.pay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 24px;
    box-sizing: border-box;
    overflow: auto;
}

.pay-alt {
    height: auto;
    min-height: 100%;
}

.payment {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    height: auto;
    max-height: 100%;
    margin: auto;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 6px 6px 54px 0px rgba(50, 23, 122, 0.05);
    overflow: hidden;
}

.payment-empty {
    height: 280px;
}

/* LOADING */

.payment-loading {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(233, 254, 255, 0.55);
    opacity: 0;
    transition: 0.5s;
    z-index: 5;
}

.payment-loading-shown {
    opacity: 1;
}

@keyframes payment-spinner-show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.payment-spinner {
    width: 54px;
    height: 54px;
    animation: payment-spinner-rotate 1.75s linear infinite;
}

.payment-spinner-path {
    stroke: #624DE3;
    stroke-linecap: round;
    animation: payment-spinner-dash 1.3125s ease-in-out infinite;
}

@keyframes payment-spinner-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes payment-spinner-dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
        stroke: #D163AB;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
        stroke: #D163AB;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
        stroke: #624DE3;
    }
}

/* FAIL */

.payment-fail {
    padding: 24px;
    padding-top: 32px;
    padding-bottom: 48px;
    box-sizing: border-box;
}

.payment-fail-picture {
    display: block;
    width: 128px;
    margin: 0 auto;
}

.payment-fail-caption {
    padding-top: 16px;
    color: #624DE3;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
}

.payment-fail-detail {
    padding-top: 4px;
    color: #624DE3;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
}

/* HEADER */

.payment-header {
    flex: none;
    position: relative;
    display: flex;
    height: 64px;
    background-color: #614DE3;
}

.payment-content {
    flex: auto;
    overflow: auto;
}

.payment-content::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    border-radius: 30px;
}

.payment-content::-webkit-scrollbar-thumb {
    background: #BCB4F3;
    border-radius: 30px;
    border: solid 4px #FFFFFF;
}

.payment-content::-webkit-scrollbar-thumb:hover {
    background: #9D92ED;
}

.payment-content::-webkit-scrollbar-track {
    background: #FFFFFF;
    border-radius: 0px;
}

.payment-brand {
    flex: auto;
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
}

.payment-brand img {
    display: block;
    height: 26px;
    margin: 0 auto;
}

.payment-back {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 100%;
    padding-top: 2px;
    box-sizing: border-box;
    cursor: pointer;
}

.payment-back img {
    display: block;
    opacity: 0.7;
    transition: 0.18s;
}

.payment-back:hover img {
    opacity: 1;
    transform: scale(1.4);
}

.touch .payment-back:hover img {
    opacity: 0.7;
    transform: scale(1);
}

.payment-back:active img,
.touch .payment-back:active img {
    opacity: 1;
    transform: scale(1.4);
}

/* INFO */

.payment-info {
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 22px;
    padding-right: 26px;
    box-sizing: border-box;
    background-color: #E8FEFF;
}

.payment-icon {
    flex: none;
}

.payment-detail {
    flex: auto;
    display: flex;
    align-items: center;
    max-width: calc(100% - 60px);
    padding-left: 12px;
    box-sizing: border-box;
}

.payment-detail>div {
    width: 100%;
}

.payment-single {
    display: flex;
    width: 100%;
}

.payment-single-detail {
    flex: auto;
    width: 100%;
    min-width: 0;
    padding-bottom: 2px;
    padding-right: 12px;
}

.payment-single-amount {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
}

.payment-shop {
    padding-bottom: 4px;
    color: #41319C;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

.payment-item {
    display: flex;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
}

.payment-item-name {
    flex: auto;
    padding-right: 10px;
    box-sizing: border-box;
    color: #41319C;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}

.payment-single-detail .payment-item-name {
    padding-right: 0px;
}

.payment-item-amount {
    flex: none;
    color: #D163AB;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

.payment-total {
    display: flex;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 6px;
    margin-top: 12px;
    border-top: solid 1px rgba(65, 49, 156, 0.5);
}

.payment-total-label {
    flex: auto;
    color: #41319C;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

.payment-total-amount {
    flex: none;
    color: #D163AB;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
}

.payment-decimals {
    opacity: 0.5;
}

/* USER */

.payment-user {
    text-align: center;
}

.payment-user-info {
    padding: 14px;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 1px #D6D3FB;
    color: #41319C;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.payment-user-avatar {
    display: flex;
    justify-content: center;
    padding-bottom: 6px;
}

.payment-user-name {
    font-size: 15px;
    font-weight: 600;
}

.payment-user-change {
    display: inline-block;
    padding-top: 3px;
    padding-bottom: 5px;
    padding-left: 13px;
    padding-right: 13px;
    margin-top: 2px;
    box-sizing: border-box;
    border-radius: 100px;
    background-color: transparent;
    color: #D163AB;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.18s;
}

.payment-user-change:hover {
    background-color: #FBEFF7;
}

.touch .payment-user-change:hover {
    background-color: transparent;
}

.payment-user-change:active,
.touch .payment-user-change:active {
    background-color: #FBEFF7;
}

/* FORMS */

.payment-block {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
}

.payment-caption {
    padding-top: 25px;
    padding-bottom: 27px;
    color: #624DE3;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
}

.payment-caption-2 {
    padding-top: 10px;
    padding-bottom: 12px;
}

.payment-caption-3 {
    padding-bottom: 18px;
}

.payment-caption-small {
    padding-top: 0px;
    padding-bottom: 16px;
    color: #32177A;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    text-align: center;
}

.payment-caption-small strong {
    font-weight: 600;
}

.payments-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
    column-gap: 10px;
    padding-bottom: 24px;
}

.payment-field {
    position: relative;
    padding-top: 0px;
    margin-bottom: 16px;
}

.payment-field:last-of-type {
    margin-bottom: 0px;
}

.payment-field-label {
    position: absolute;
    top: 20px;
    left: 21px;
    background-color: #DFDBFF;
    color: #675AB0;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    cursor: text;
    transition: 0.18s;
}

.payment-field-on .payment-field-label {
    top: 11px;
    left: 13px;
    font-size: 13px;
    line-height: 13px;
}

.payment-field-symbol {
    position: absolute;
    top: 28px;
    left: 14px;
    color: #675AB0;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    opacity: 0;
    transition: 0.18s;
}

.payment-field-on .payment-field-symbol {
    opacity: 1;
}

.payment-textbox {
    display: block;
    width: 100%;
    height: 57px;
    padding-top: 18px;
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 0px;
    margin: 0;
    box-sizing: border-box;
    border: solid 1px #DFDBFF;
    border-radius: 8px;
    background-color: #DFDBFF;
    color: #32177A;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    transition: 0.18s;
}

.payment-field-currency .payment-textbox {
    padding-left: 28px;
}

.payment-textbox:focus {
    border-color: #624DE3;
    box-shadow: 0 0 0 1px #624DE3;
}

.payment-textbox:disabled {
    color: rgba(65, 49, 156, 0.35);
    cursor: not-allowed;
}

.payment-button {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0;
    padding-left: 22px;
    padding-right: 22px;
    margin: 0;
    margin-top: 36px;
    margin-bottom: 42px;
    box-sizing: border-box;
    border-style: none;
    border-radius: 100px;
    background-color: #D163AB;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
    transition: 0.18s;
}

.payment-button img {
    display: inline-block;
    width: auto;
    height: 13px;
    margin: 0 0 0;
    margin-top: -1px;
    margin-left: 8px;
    margin-right: 0;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.payment-button:hover {
    background-color: #DB68B4;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75);
}

.touch .payment-button:hover {
    background-color: #D163AB;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35);
}

.payment-button:active,
.touch .payment-button:active {
    background-color: #DB68B4;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75);
}

.payment-button:hover img {
    transform: translateX(4px);
}

.touch .payment-button:hover img {
    transform: translateX(0px);
}

.payment-button:active img,
.touch .payment-button:active img {
    transform: translateX(4px);
}

.payment-button-alt {
    margin-bottom: 32px;
}

.payment-button-alt img {
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0 0 0;
    margin-top: -4px;
    margin-left: 0;
    margin-right: 12px;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    transition: 0.18s;
}

.payment-button-alt:hover img {
    transform: translateX(0px);
}

.touch .payment-button-alt:hover img {
    transform: translateX(0px);
}

.payment-button-alt:active img,
.touch .payment-button-alt:active img {
    transform: translateX(0px);
}

/* METHODS */

.payment-methods {
    border: solid 1px #D6D3FB;
    border-radius: 10px;
    overflow: hidden;
}

.payment-method {
    display: flex;
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: solid 1px #D6D3FB;
    cursor: pointer;
    transition: 0.18s;
}

.payment-method:last-of-type {
    border-bottom: none;
}

.payment-method:hover {
    background-color: #D6D3FB;
}

.touch .payment-method:hover {
    background-color: transparent;
}

.payment-method:active,
.touch .payment-method:active {
    background-color: #D6D3FB;
}

.payment-method-icon {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 14px;
    padding-right: 12px;
    box-sizing: border-box;
}

.payment-method-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 100px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(98, 77, 227, 0.3);
    overflow: hidden;
    transform: scale(1);
    transition: 0.18s;
}

.payment-method-pic-on {
    box-shadow: 0px 0px 20px 0px rgba(98, 77, 227, 1);
}

.payment-method-pic-nav {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    margin-top: 20px;
}

.payment-method-pic img {
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
}

.payment-method-pic-entity img {
    width: 100%;
    height: 100%;
}

.payment-method:hover .payment-method-pic {
    transform: scale(1.25);
}

.touch .payment-method:hover .payment-method-pic {
    transform: scale(1);
}

.payment-method:active .payment-method-pic,
.touch .payment-method:active .payment-method-pic {
    transform: scale(1.25);
}

.payment-installment-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #4837AB;
    background: -moz-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: -webkit-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: -1px;
}

.payment-installments-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #4837AB;
    background: -moz-linear-gradient(45deg, #4837AB 0%, #D163AB 100%);
    background: -webkit-linear-gradient(45deg, #4837AB 0%, #D163AB 100%);
    background: linear-gradient(45deg, #4837AB 0%, #D163AB 100%);
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: -1px;
}

.payment-installments-ahora-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #604688;
    background: -moz-linear-gradient(45deg, #604688 0%, #0090d0 100%);
    background: -webkit-linear-gradient(45deg, #604688 0%, #0090d0 100%);
    background: linear-gradient(45deg, #604688 0%, #0090d0 100%);
    color: #FFFFFF;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: -1px;
}

.payment-method-caption {
    flex: auto;
    display: flex;
    align-items: center;
    padding-bottom: 1px;
}

.payment-method-name {
    color: #32177A;
    font-size: 15px;
    line-height: 21px;
    font-weight: 600;
}

.payment-method-detail {
    padding-top: 2px;
    color: #8474AF;
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
}

.payment-method-link,
.payment-method-link:link {
    text-decoration: none;
    cursor: pointer;
}

.payment-method-link:hover {
    text-decoration: underline;
}

.touch .payment-method-link:hover {
    text-decoration: none;
}

.payment-method-link:active,
.touch .payment-method-link:active {
    text-decoration: underline;
}

.payment-method-arrow {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.payment-method-arrow img {
    display: block;
    width: 7px;
}

/* SECURE */

.payment-secure-frame {
    padding-top: 20px;
    padding-bottom: 32px;
    text-align: center;
}

.payment-secure-frame-2 {
    padding-top: 0px;
    margin-top: -12px;
}

.payment-secure {
    display: inline-flex;
}

.qr-img {
    max-width: 70%
}

.payment-secure-icon {
    flex: none;
    padding-right: 8px;
}

.payment-secure-icon img {
    display: block;
}

.payment-secure-caption {
    flex: auto;
    color: #ACA6FE;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
}

/*  PAYZEN */

.payzen-form {
    min-height: 200px;
}

.payzen-form-content {
    display: none;
}

.kr-embedded {
    display: block !important;
}

.kr-field-wrapper {
    position: relative !important;
    height: 57px !important;
    margin-bottom: 16px !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    border: solid 1px #DFDBFF !important;
    background-color: #DFDBFF !important;
    transition: 0.18s !important;
}

.kr-field-wrapper-focus {
    border-color: #624DE3 !important;
    box-shadow: 0 0 0 1px #624DE3 !important;
}

.kr-form-error {
    display: none !important;
}

.kr-label {
    position: absolute !important;
    top: 10px !important;
    left: 12px !important;
    color: #675AB0 !important;
    font-size: 13px !important;
    line-height: 13px !important;
    font-weight: 500 !important;
    transition: 0.18s !important;
    z-index: 1 !important;
}

.kr-label-focus {
    top: 10px !important;
    left: 12px !important;
    font-size: 13px !important;
    line-height: 13px !important;
}

.kr-label-active {
    top: 10px !important;
    left: 12px !important;
    font-size: 13px !important;
    line-height: 13px !important;
}

.kr-installment-number .kr-label {
    display: none !important;
}

.kr-indication {
    display: none;
    position: absolute !important;
    top: 16px !important;
    left: 0px !important;
    color: #BBB4E7 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
    opacity: 0;
    white-space: nowrap !important;
    animation: kr-indication-anima 0.18s ease-in-out forwards;
}

.kr-label-focus .kr-indication {
    display: block !important;
}

@keyframes kr-indication-anima {
    to {
        opacity: 1;
    }
}

.kr-label-active .kr-indication {
    display: none !important;
}

.kr-pan .kr-indication::before {
    content: "0000 0000 0000 0000";
}

.kr-expiry .kr-indication::before {
    content: "MM/AA";
}

.kr-security-code .kr-indication::before {
    content: "000";
}

.kr-field-component {
    position: relative !important;
    z-index: 2 !important;
}

.kr-field-component iframe {
    display: block !important;
    height: 57px !important;
    padding: 0 !important;
    margin: 0 !important;
}

.kr-input-field {
    display: block !important;
    width: 100% !important;
    height: 55px !important;
    padding-top: 15px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 0px !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    background-color: transparent !important;
    border: none !important;
    color: #32177A !important;
    font-size: 16px !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    transition: 0.18s !important;
}

.kr-visibility-button {
    display: none !important;
}

.payment-cvv-frame {
    position: relative;
}

.payment-cvv {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 16px;
    height: 100%;
    opacity: 0;
    transition: 0.18s;
}

.payment-cvv img {
    display: block;
    height: 25px;
}

.payment-cvv-focus {
    opacity: 1 !important;
}

.kr-payment-button {
    display: block !important;
    width: 100% !important;
    height: 50px !important;
    padding: 0 !important;
    padding-left: 22px !important;
    padding-right: 22px !important;
    margin: 0 !important;
    margin-top: 32px !important;
    margin-bottom: 32px !important;
    box-sizing: border-box !important;
    border-style: none !important;
    border-radius: 100px !important;
    background-color: #D163AB !important;
    color: #FFFFFF !important;
    font-size: 18px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    text-align: center !important;
    cursor: pointer !important;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35) !important;
    transition: 0.18s !important;
}

.kr-payment-button img {
    display: inline-block !important;
    width: auto !important;
    height: auto !important;
    margin: 0 0 0 !important;
    margin-top: -3px !important;
    margin-left: 0 !important;
    margin-right: 12px !important;
    font-size: inherit !important;
    line-height: normal !important;
    vertical-align: middle !important;
    transition: 0.18s !important;
}

.kr-payment-button:hover {
    background-color: #DB68B4 !important;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75) !important;
}

.touch .kr-payment-button:hover {
    background-color: #D163AB !important;
    box-shadow: 0 6px 12px 0px rgba(209, 99, 171, 0.35) !important;
}

.kr-payment-button:active,
.touch .kr-payment-button:active {
    background-color: #DB68B4 !important;
    box-shadow: 0 6px 20px 0px rgba(209, 99, 171, 0.75) !important;
}

.kr-select {
    display: block !important;
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    padding-top: 18px !important;
    padding-left: 20px !important;
    padding-right: 12px !important;
    padding-bottom: 0px !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    background-color: transparent !important;
    border: none !important;
    color: #32177A !important;
    font-size: 16px !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    transition: 0.18s !important;
    z-index: 2 !important;
}

.kr-select-active {
    padding-top: 26px !important;
    padding-left: 12px !important;
}

.kr-select-caret {
    position: absolute !important;
    top: 8px !important;
    right: 0 !important;
    width: 37px !important;
    height: 39px !important;
    box-sizing: border-box !important;
    border-left: solid 1px rgba(98, 77, 227, 0.2) !important;
    background-color: transparent !important;
    background-image: url(../src/assets/dropdown.svg) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.kr-select-caret svg {
    display: none !important;
}

.kr-options {
    position: absolute !important;
    top: -2px !important;
    left: -2px !important;
    width: calc(100% + 4px) !important;
    height: auto !important;
    max-height: 189px !important;
    padding: 4px !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    border: solid 2px #624DE3 !important;
    background-color: #FFFFFF !important;
    box-shadow: none !important;
    animation-name: kr-options-anima !important;
    animation-duration: 0.18s !important;
    z-index: 3 !important;
}

@keyframes kr-options-anima {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.kr-options::-webkit-scrollbar {
    width: 11px;
    height: 11px;
    border-radius: 30px;
}

.kr-options::-webkit-scrollbar-thumb {
    background: #B0A6F1;
    border-radius: 30px;
    border: solid 3px #FFFFFF;
}

.kr-options::-webkit-scrollbar-thumb:hover {
    background: #624DE3;
}

.kr-options::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 30px;
}

.kr-option {
    padding: 11px !important;
    padding-bottom: 12px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    box-sizing: border-box !important;
    border-radius: 7px !important;
    background-color: #FFFFFF !important;
    color: #32177A !important;
    font-size: 15px !important;
    line-height: 17px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    transition: 0.18s !important;
}

.kr-option::last-word {
    color: #00F !important;
}

.kr-option strong {
    font-weight: 600 !important;
}

.kr-option:hover {
    background-color: #DFDBFF !important;
}

.touch .kr-option:hover {
    background-color: #FFFFFF !important;
}

.kr-option:active,
.touch .kr-option:active {
    background-color: #DFDBFF !important;
}

.kr-select-placeholder {
    position: absolute !important;
    top: 17px !important;
    left: 20px !important;
    color: #BBB4E7 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    letter-spacing: 0px !important;
    opacity: 1 !important;
}

.kr-pan.kr-disabled {
    display: none !important;
}

.kr-expiry.kr-disabled {
    display: none !important;
}

/* ERROR */

.payment-error {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    box-sizing: border-box;
    background-color: #EB3941;
    animation-name: payment-error-anima;
    animation-duration: 0.18s;
    z-index: 2;
}

@keyframes payment-error-anima {
    0% {
        transform: scale(0.95);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.payment-error-content {
    width: 100%;
    max-width: 320px;
}

.payment-error-pic {
    margin-bottom: 24px;
}

.payment-error-pic img {
    display: block;
    width: 96px;
    margin: 0 auto;
}

.payment-error-title {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
}

.payment-error-detail {
    padding-top: 10px;
    color: rgba(255, 255, 255, 0.75);
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
}

.payment-error-button {
    text-align: center;
}

.payment-error-retry {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 24px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-radius: 100px;
    background-color: #FFFFFF;
    color: #EB3941;
    font-size: 17px;
    line-height: 17px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: 0.18s;
}

.payment-error-retry:hover {
    color: #FFFFFF;
    background-color: #EB3941;
    border-color: #FFFFFF;
}

.touch .payment-error-retry:hover {
    color: #EB3941;
    background-color: #FFFFFF;
    border-color: transparent;
}

.payment-error-retry:active,
.touch .payment-error-retry:active {
    color: #FFFFFF;
    background-color: #EB3941;
    border-color: #FFFFFF;
}

/* SUCCESS */

.payment-success {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
    box-sizing: border-box;
    background: #4837AB;
    background: -moz-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: -webkit-linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    background: linear-gradient(45deg, #4837AB 0%, #624DE3 100%);
    animation-name: payment-success-anima;
    animation-duration: 0.18s;
}

@keyframes payment-success-anima {
    0% {
        transform: scale(0.95);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.payment-success-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
}

.payment-success-brand {
    flex: none;
}

.payment-success-brand img {
    display: block;
    height: 26px;
    margin: 0 auto;
}

.payment-success-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 152px;
    height: 152px;
    margin: 0 auto;
    margin-top: 24px;
}

.payment-success-avatar {
    transform: scale(0.5);
    opacity: 0;
    animation-name: payment-avatar-anima;
    animation-delay: 0.25s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes payment-avatar-anima {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.payment-success-sparkles {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.payment-success-sparkles img {
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(0) rotate(-360deg);
    opacity: 0;
    animation-name: payment-sparkles-anima;
    animation-delay: 0.25s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes payment-sparkles-anima {
    0% {
        transform: scale(0) rotate(-360deg);
        opacity: 0;
    }

    100% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
}

.payment-success-returning {
    flex: none;
    padding-top: 24px;
    padding-bottom: 18px;
    color: #75E8F0;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
}

.payment-success-caption {
    padding-top: 19px;
    padding-bottom: 24px;
    color: #75E8F0;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
}

.payment-success-caption-alt {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
}

.payment-success-amount {
    color: #FFFFFF;
}

.payment-success-button,
.payment-success-button:link,
.payment-success-button:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    width: 236px;
    height: 44px;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 14px;
    box-sizing: border-box;
    border-radius: 100px;
    border: solid 2px #FFFFFF;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: 0.18s;
}

.payment-success-button svg {
    display: block;
    height: 20px;
}

.payment-success-button:hover {
    background-color: #75E8F0;
    border-color: transparent;
}

.touch .payment-success-button:hover {
    background-color: transparent;
    border-color: #FFFFFF;
}

.payment-success-button:active,
.touch .payment-success-button:active {
    background-color: #75E8F0;
    border-color: transparent;
}

.payment-success-button-alt,
.payment-success-button-alt:link,
.payment-success-button-alt:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    width: 236px;
    height: 44px;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 14px;
    box-sizing: border-box;
    border-radius: 100px;
    border: solid 2px #75E8F0;
    color: #75E8F0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: 0.18s;
}

.payment-success-button-alt svg {
    display: block;
    height: 18px;
    margin-top: -2px;
}

.payment-success-button-alt:hover {
    color: #FFFFFF;
    background-color: #75E8F0;
    border-color: transparent;
}

.touch .payment-success-button-alt:hover {
    color: #75E8F0;
    background-color: transparent;
    border-color: #75E8F0;
}

.payment-success-button-alt:active,
.touch .payment-success-button-alt:active {
    color: #FFFFFF;
    background-color: #75E8F0;
    border-color: transparent;
}

.payment-success-button-alt path {
    transition: 0.18s;
}

.payment-success-button-alt:hover path {
    fill: #FFFFFF;
}

.touch .payment-success-button-alt:hover path {
    fill: #75E8F0;
}

.payment-success-button-alt:active path,
.touch .payment-success-button-alt:active path {
    fill: #FFFFFF;
}

.payment-returning-button,
.payment-returning-button:link,
.payment-returning-button:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    width: 236px;
    height: 44px;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 14px;
    box-sizing: border-box;
    border-radius: 100px;
    border: solid 2px transparent;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: 0.18s;
}

.payment-returning-button svg {
    display: block;
    height: 18px;
    margin-top: -2px;
}

.payment-returning-button:hover {
    border-color: #FFFFFF;
}

.touch .payment-returning-button:hover {
    border-color: transparent;
}

.payment-returning-button:active,
.touch .payment-returning-button:active {
    border-color: #FFFFFF;
}

.payment-pending {
    width: 130px;
    padding: 8px;
    padding-bottom: 10px;
    margin: 0 auto;
    margin-bottom: 10px;
    border: solid 2px #F5D000;
    border-radius: 100px;
    color: #F5D000;
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    text-align: center;
}

/* CALENDAR */

.calendar-event {
    padding: 10px;
    box-sizing: border-box;
}

.calendar-event-off {
    opacity: 0.3;
}

.calendar-info {
    padding: 10px;
    padding-left: 11px;
    padding-right: 11px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #FBEFF7;
    color: #D163AB;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    text-transform: none;
    cursor: pointer;
    transition: 0.18s;
}

.calendar-event-off .calendar-info {
    cursor: default;
}

.calendar-details {
    padding-top: 4px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    opacity: 0.75;
}

.calendar-info:hover {
    background-color: #D163AB;
    color: #FFFFFF;
}

.touch .calendar-info:hover {
    background-color: #FBEFF7;
    color: #D163AB;
}

.calendar-info:active,
.touch .calendar-info:active {
    background-color: #D163AB;
    color: #FFFFFF;
}

.calendar-event-off .calendar-info:hover {
    background-color: #FBEFF7;
    color: #D163AB;
}

.calendar-event-off .touch .calendar-info:hover {
    background-color: #FBEFF7;
    color: #D163AB;
}

.calendar-event-off .calendar-info:active,
.calendar-event-off .touch .calendar-info:active {
    background-color: #FBEFF7;
    color: #D163AB;
}

.rc-bg-white {
    position: relative;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 54px 0px rgba(50, 23, 122, 0.05);
    overflow: hidden;
}

.rc-grid {
    display: grid;
    width: calc(100% + 1px);
}

.rc-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.rc-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.rc-border-b-2 {
    background-color: #FFFFFF;
    border: solid 1px transparent;
    border-bottom-color: #EEEBFD !important;
    border-right-color: #EEEBFD;
    text-transform: capitalize;
}

.rc-border-b-2[aria-label="Day of Week"] {
    padding: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-right-color: transparent;
    background-color: #F7F4FF;
    color: #9748A3;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    text-align: center;
}

.rc-border-b-2:nth-child(7n+1):nth-last-child(-n+7),
.rc-border-b-2:nth-child(7n+1):nth-last-child(-n+7)~.rc-border-b-2 {
    border-bottom-color: transparent;
}

.rc-h-48 {
    height: 192px;
    padding-right: 1px !important;
}

.rc-flex {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 11px;
    box-sizing: border-box;
}

.rc-font-bold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 100px;
    background-color: #F7F4FF;
    color: #624DE3;
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
}

.rc-flex div:nth-child(2) {
    padding-top: 9px;
    color: #9748A3;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
}

.rc-max-h-36 {
    display: block;
}

.rc-divide-gray-200 {
    display: block;
    padding: 0;
    margin: 0;
}

.rc-overflow-hidden {
    overflow: hidden;
}

.rc-overflow-y-auto {
    overflow-y: auto;
}

.rc-block {
    display: block;
}

.rc-hidden {
    display: none;
}

@media (min-width: 800px) {

    .sm\:rc-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .rc-border-b-2:nth-child(7n+1):nth-last-child(-n+7),
    .rc-border-b-2:nth-child(7n+1):nth-last-child(-n+7)~.rc-border-b-2 {
        border-bottom-color: #EEEBFD;
    }

    .rc-border-b-2:nth-child(2n+1):nth-last-child(-n+2),
    .rc-border-b-2:nth-child(2n+1):nth-last-child(-n+2)~.rc-border-b-2 {
        border-bottom-color: transparent !important;
    }

}

@media (min-width: 1024px) {

    .md\:rc-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .rc-border-b-2:nth-child(7n+1):nth-last-child(-n+7),
    .rc-border-b-2:nth-child(7n+1):nth-last-child(-n+7)~.rc-border-b-2 {
        border-bottom-color: #EEEBFD;
    }

    .rc-border-b-2:nth-child(4n+1):nth-last-child(-n+4),
    .rc-border-b-2:nth-child(4n+1):nth-last-child(-n+4)~.rc-border-b-2 {
        border-bottom-color: transparent !important;
    }

}

@media (min-width: 1300px) {

    .lg\:rc-block {
        display: block;
    }

    .lg\:rc-hidden {
        display: none;
    }

    .lg\:rc-grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    .rc-border-b-2:nth-child(7n+1):nth-last-child(-n+7),
    .rc-border-b-2:nth-child(7n+1):nth-last-child(-n+7)~.rc-border-b-2 {
        border-bottom-color: #EEEBFD;
    }

}

/* REPORTS */

.charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 100px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
}

.chart {
    padding: 24px;
    padding-bottom: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #32177A;
    -webkit-box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
}

.chart-title {
    padding-top: 6px;
    padding-bottom: 4px;
    color: #32177A;
    font-size: 21px;
    line-height: 27px;
    font-weight: 500;
    text-align: center;
}

.chart-subtitle {
    color: #32177A;
    font-size: 21px;
    line-height: 27px;
    font-weight: 700;
    text-align: center;
}

.chart-note {
    color: #32177A;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
}

.chart-note div {
    display: inline-block;
    padding-top: 3px;
    padding-bottom: 4px;
    padding-left: 11px;
    padding-right: 11px;
    margin-top: 8px;
    margin-bottom: 12px;
    border-radius: 100px;
    background-color: rgba(98, 77, 227, 0.12);
}

.chart-graphic {
    width: 100%;
    height: 360px;
}


.chart-legends {
    text-align: center;
}

.chart-legend {
    display: inline-flex;
    column-gap: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
}

.chart-legend-shape {
    flex: none;
    width: 14px;
    height: 14px;
    margin-top: 1px;
    border-radius: 50px;
}

.chart-legend-caption {
    flex: auto;
    white-space: nowrap;
    color: #32177A;
    font-size: 13px;
    line-height: 16px;
}

/* RESPONSIVE */

@media (max-width: 1300px) {

    .home-prices-grid {
        grid-template-columns: 1fr 1fr;
    }

    .home-prices-grid-alt {
        grid-template-columns: 1fr 1fr;
    }

}

@media (max-width: 1200px) {

    .entrance-intro {
        width: 50%;
    }

    .entrance-content {
        width: 50%;
    }

}

@media (max-width: 1024px) {

    .home-entities-slide {
        width: 50% !important;
    }

    .entrance-intro {
        width: 40%;
    }

    .entrance-content {
        width: 60%;
    }

    .entrance-intro-caption {
        font-size: 26px;
        line-height: 32px;
    }

}

@media (max-width: 1000px) {

    .blog-articles {
        grid-template-columns: 1fr 1fr;
    }

}

@media (max-width: 980px) {

    .dashboard {
        padding-left: 0px;
    }

    .bar {
        display: flex;
    }

    .panel {
        position: fixed;
        left: -100%;
    }

    .panel-opened {
        left: 0%;
    }

    .panel-bg-opened {
        display: block;
    }

    .panel-brand {
        display: none;
    }

    .panel-close {
        display: flex;
    }

}

@media (max-width: 840px) {

    .entrance {
        flex-direction: column;
        height: auto;
    }

    .entrance-intro {
        order: 2;
        width: 100%;
        height: 500px;
    }

    .entrance-content {
        order: 1;
        width: 100%;
        height: auto;
    }

    .entrance-intro-caption {
        font-size: 26px;
        line-height: 32px;
    }

    .entrance-bar {
        height: 78px;
    }

    .entrance-caption {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 32px;
    }

}

@media (max-width: 768px) {

    .home-bar-frame {
        padding-left: 24px;
        padding-right: 24px;
    }

    .home-bar-actions {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .home-intro-content {
        padding-left: 10%;
        padding-right: 10%;
    }

    .home-marquee {
        padding-top: 62px;
        padding-bottom: 80px;
    }

    .home-channels {
        padding-top: 70px;
        padding-bottom: 100px;
    }

    .home-channels-title {
        padding-bottom: 48px;
    }

    .home-channels-grid {
        grid-template-columns: 1fr 1fr;
        padding-left: 24px;
        padding-right: 24px;
        column-gap: 24px;
        row-gap: 32px;
    }

    .home-channel-button-frame {
        padding-top: 52px;
    }

    .home-entities {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .home-entities-swiper {
        padding-left: 12px !important;
        padding-right: 12px !important;
        padding-top: 34px !important;
        padding-bottom: 34px !important;
    }

    .home-entities-slide {
        padding-left: 12px;
        padding-right: 12px;
    }

    .home-payments {
        padding-top: 80px;
        padding-bottom: 100px;
    }

    .home-payments-caption {
        padding-left: 24px;
        padding-right: 24px;
    }

    .home-prices {
        padding-top: 80px;
        padding-bottom: 60px;
    }

    .home-prices-grid {
        column-gap: 24px;
        row-gap: 24px;
        padding-top: 34px;
        padding-bottom: 34px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .footer-layout {
        grid-template-columns: 1fr;
    }

}

@media (max-width: 720px) {

    .content-focused {
        padding-top: 50px;
        padding-bottom: 44px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .start-boxes {
        grid-template-columns: 1fr;
        margin-top: 20px;
    }

    .shortcuts {
        margin-left: 0px;
        text-align: center;
    }

    .networks {
        margin-top: 20px;
    }

    .box-info .networks {
        margin-top: 0px !important;
    }

    .news {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .news-item {
        padding: 24px;
        padding-top: 24px;
    }

    .blog-articles {
        grid-template-columns: 1fr;
        row-gap: 24px;
        column-gap: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .items-header-analytics {
        display: none;
    }

    .item-row-alt {
        padding: 16px;
    }

    .item-row-alt .item-data {
        flex-direction: column;
        row-gap: 8px;
        padding-left: 0px;
        padding-bottom: 0px;
    }

    .item-row-alt .item-data .item-data-center {
        margin-top: 0px;
    }

    .item-row-alt .item-data>div {
        justify-content: center;
        text-align: center;
    }

    .items-header-records {
        display: none;
    }

    .item-record {
        flex-direction: column;
    }

    .item-record .item-align-right {
        text-align: left;
    }

    .item-record-1 {
        justify-content: flex-start;
        text-align: left;
    }

    .item-record-1 .item-semibold {
        padding-right: 8px;
    }

    .item-record-2 .item-data-center {
        display: block;
        width: auto !important;
    }

    .item-record-2>div:nth-child(2) {
        display: flex;
        flex: auto;
        justify-content: flex-end;
    }

    .item-tag {
        display: inline-block;
        margin-left: -5px;
    }

    .counter {
        display: none;
    }

    .tools {
        display: flex;
        flex-direction: column;
    }

    .tools-right {
        flex: none;
        display: flex;
        justify-content: flex-start;
        column-gap: 10px;
    }

    .tools-left {
        display: flex;
        flex-direction: column;
    }

}

@media (max-width: 640px) {

    .header {
        padding-top: 32px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .header-data {
        flex-direction: column;
    }

    .header-info {
        padding-left: 4px;
        padding-bottom: 8px;
    }

    .header-actions {
        margin-bottom: 16px;
    }

    .big-button,
    .big-button:link,
    .big-button:visited {
        display: inline-flex;
    }

    .tab-label-long {
        display: none;
    }

    .tab-label-short {
        display: inline;
    }

    .content-frame {
        padding: 16px;
    }

    .content-frame-cards {
        padding: 16px;
        padding-top: 20px;
    }

    .content-frame-top {
        padding-top: 20px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .content-frame-top-single {
        padding-top: 0px;
    }

    .boxes {
        row-gap: 16px;
        padding-bottom: 80px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .box {
        flex-direction: column;
        padding: 20px;
    }

    .box-info {
        order: 2;
    }

    .box-picture {
        order: 1;
        justify-content: flex-start;
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 12px;
    }

    .box-picture img {
        width: 130px;
    }

    .box-info .network,
    .box-info .network:link,
    .box-info .network:visited {
        width: 42px;
        height: 42px;
    }

    .charts {
        grid-template-columns: 1fr;
        row-gap: 16px;
        padding-top: 20px;
        padding-bottom: 80px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .chart {
        padding: 20px;
        padding-bottom: 36px;
    }

    .symbol {
        width: 42px;
        height: 42px;
    }

    .symbol-tiny {
        width: 20px;
        height: 20px;
    }

    .avatar-medium {
        width: 42px;
        height: 42px;
    }

    .icon-medium {
        width: 42px;
        height: 42px;
    }

    .item-data-alt {
        flex-direction: column;
    }

    .item-data-center {
        width: 100%;
        margin-top: 9px;
    }

    .item-data-full {
        width: 100%;
    }

    .webpos {
        padding-top: 24px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .webpos-icon {
        margin-top: 24px;
    }

    .webpos-block {
        padding-top: 16px;
    }

    .webpos-concept {
        flex-direction: column;
    }

    .webpos-concept-detail {
        width: 100%;
    }

    .webpos-concept-reference {
        width: 100%;
    }

    .webpos-item {
        flex-direction: column;
    }

    .webpos-item-detail {
        width: 100%;
    }

    .webpos-item-reference {
        width: 100%;
    }

    .webpos-item-amount {
        width: 100%;
    }

    .webpos-detail-alt {
        text-align: center;
    }

}

@media (max-width: 620px) {

    .home-bar-actions {
        display: none;
    }

    .home-bar-frame {
        padding-right: 14px;
    }

    .home-bar-menu {
        display: flex;
    }

    .home-bar-brand {
        padding-top: 22px;
        padding-bottom: 21px;
    }

    .home-bar-brand img {
        height: 34px;
    }

    .home-action {
        height: 48px;
        padding-left: 18px;
        padding-right: 18px;
        font-size: 16px;
        line-height: 16px;
    }

    .home-action-alt {
        height: 48px;
        padding-left: 18px;
        padding-right: 18px;
        font-size: 16px;
        line-height: 16px;
    }

    .home-intro-caption {
        font-size: 50px;
        line-height: 50px;
    }

    .home-intro-mask {
        height: 54px;
    }

    .home-intro-word {
        top: 51px;
        padding-bottom: 4px;
    }

    .home-intro-text {
        padding-top: 12px;
        font-size: 18px;
        line-height: 26px;
    }

    .home-intro-button {
        height: 56px;
        padding-left: 28px;
        padding-right: 28px;
        margin-top: 32px;
        font-size: 17px;
        line-height: 17px;
    }

    .home-marquee-title {
        font-size: 28px;
        line-height: 34px;
    }

    .home-channels {
        padding-top: 50px;
        padding-bottom: 80px;
    }

    .home-channels-title {
        padding-bottom: 32px;
        font-size: 28px;
        line-height: 34px;
    }

    .home-channels-grid {
        grid-template-columns: 1fr;
    }

    .home-entities {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .home-entities-title {
        font-size: 28px;
        line-height: 34px;
    }

    .home-entities-slide {
        width: 100% !important;
    }

    .home-payments {
        padding-top: 60px;
        padding-bottom: 80px;
    }

    .home-payments-caption {
        font-size: 26px;
        line-height: 32px;
    }

    .home-prices-title {
        font-size: 28px;
        line-height: 34px;
    }

    .home-prices {
        padding-top: 60px;
        padding-bottom: 40px;
    }

    .home-prices-grid {
        grid-template-columns: 1fr;
    }

    .home-prices-grid-alt {
        grid-template-columns: 1fr;
    }

    .footer-networks-title {
        padding-top: 32px;
        font-size: 28px;
        line-height: 34px;
    }

    .blog-intro-title {
        padding-top: 32px;
        font-size: 52px;
        line-height: 58px;
    }

    .blog-intro-caption {
        font-size: 19px;
        line-height: 27px;
    }

    .blog-intro-button {
        height: 56px;
        padding-left: 28px;
        padding-right: 28px;
        margin-top: 32px;
        font-size: 17px;
        line-height: 17px;
    }

    .blog-title {
        padding-top: 48px;
        padding-bottom: 48px;
        font-size: 28px;
        line-height: 34px;
    }

}

@media (max-width: 580px) {

    .modal-info {
        padding: 16px;
    }

    .modal-form {
        padding: 16px;
        padding-bottom: 64px;
    }

    .card {
        padding: 16px;
        padding-top: 22px;
        padding-bottom: 28px;
        margin-bottom: 16px;
    }

    .card-header {
        display: grid;
        grid-template-columns: 53px 1fr;
    }

    .card-header>div:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 1;
    }

    .card-header>div:nth-child(3) {
        display: flex;
        justify-content: flex-end;
        grid-column-start: 1;
        grid-column-end: 3;
        padding-bottom: 2px;
        border-bottom: solid 1px #EEEBFD;
    }

    .card-header>div:nth-child(3)>div {
        margin-top: -6px;
        padding-bottom: 2px;
    }

    .card-data {
        padding-left: 0px;
    }

    .card-row {
        flex-direction: column;
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .card-button {
        margin-top: 7px;
        margin-bottom: 3px;
    }

    .card-icon {
        margin-left: -2px;
    }

    .card-tag {
        margin-top: 4px;
        margin-left: 0px;
        margin-right: 4px;
    }

}

@media (max-width: 480px) {

    .footer-layout {
        padding-left: 24px;
        padding-right: 24px;
    }

    .footer-networks-title {
        padding-left: 24px;
        padding-right: 24px;
    }

    .footer-networks-links {
        padding-left: 24px;
        padding-right: 24px;
    }

    .networks {
        max-width: 300px;
    }

    .footer-divisor {
        padding-left: 24px;
        padding-right: 24px;
    }

    .dialog-frame {
        padding: 16px;
    }

    .dialog-message {
        padding-top: 30px;
        padding-bottom: 20px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .modal-frame {
        padding: 0px;
    }

    .modal-content::-webkit-scrollbar {
        display: none;
    }

    .floating-frame {
        padding: 12px;
    }

    .greeting {
        padding-top: 16px;
        padding-bottom: 10px;
        font-size: 26px;
        line-height: 36px;
    }

    .pay {
        padding: 10px;
    }

    .payment-info {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 12px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .payment-icon {
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
        margin-bottom: 8px;
        border-bottom: solid 1px rgba(65, 49, 156, 0.15);
    }

    .payment-detail {
        max-width: 100%;
        padding-left: 0px;
    }

    .payment-block {
        padding-left: 16px;
        padding-right: 16px;
    }

    .article-intro-title {
        font-size: 40px;
        line-height: 50px;
    }

    .article-content-summary {
        font-size: 20px;
        line-height: 28px;
    }

    .article-content h2,
    .article-content h3,
    .article-content p,
    .article-content ul,
    .article-content ol,
    .article-content-summary,
    .article-image,
    .article-content-embed {
        padding-left: 24px;
        padding-right: 24px;
    }

    .article-image-full {
        padding-left: 0;
        padding-right: 0;
    }

}